// src/DietaryProfiles.js
export const DIETARY_PROFILES = {
  1: 'Vegan',
  2: 'Gluten Free',
  4: 'Vegetarian',
  8: 'Dairy Free',
  16: 'Lactose Free',
  32: 'Nut Free',
  64: 'Egg Free',
  128: 'Seafood Free',
  256: 'Soy Free',
  512: 'Low Sodium',
  1024: 'Pescetarian',
  2048: 'CarnivoreMD',
  4096: 'Low Carb'
}

export const getDietaryProfiles = (dietaryProfileBitMask) => {
  const profiles = []
  for (const bit in DIETARY_PROFILES) {
    if ((dietaryProfileBitMask & bit) !== 0) {
      profiles.push(DIETARY_PROFILES[bit])
    }
  }
  return profiles.join(', ')
}
