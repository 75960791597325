// At the top of your ListClients.js file
import { getMuscleList } from '../MuscleList'

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import { decodeToken } from 'react-jwt'

const ListExercises = ({ coachId }) => {
  console.log('Coach ID: ' + coachId)
  const [exercises, setExercises] = useState([])
  const [sortConfig, setSortConfig] = useState({})

  const token = localStorage.getItem('token')
  const decodedToken = decodeToken(token)

  const onSort = (key) => {
    let direction = 'ascending'
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  const params = new URLSearchParams(window.location.search)
  const selectedClient = params.get('client_id')

  const [activeExercise, setActiveExercise] = useState(0)

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const openDialog = () => {
    setOpenDeleteDialog(true)
  }

  const closeDialog = () => {
    setOpenDeleteDialog(false)
  }

  const deleteExercise = () => {
    const url = `${process.env.REACT_APP_API_URL}/exercise/${activeExercise}`
    axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        window.location.reload()
      })
      .catch(error => {
        console.error('There was an error deleting the exercise!', error)
      })
      .finally(e => {
        closeDialog()
      })
  }

  useEffect(() => {
    let url = `${process.env.REACT_APP_API_URL}/exercises`
    if (selectedClient) url += `?client_id=${selectedClient}`
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setExercises(response.data)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }, [])

  const sortedExercises = [...exercises]

  if (sortConfig !== null) {
    sortedExercises.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1
      }
      return 0
    })
  }

  return (
    <div>
      <Dialog
        open={openDeleteDialog}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{'Confirm Deletion'}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this exercise entry?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={closeDialog} color="primary">
                Cancel
            </Button>
            <Button onClick={deleteExercise} color="primary" autoFocus>
                Yes, Delete
            </Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              { decodedToken.role === 'admin' && <TableCell>Delete?</TableCell>}
              <TableCell sortDirection={sortConfig.direction}>
                <TableSortLabel active={sortConfig.key === 'name'} direction={sortConfig.direction} onClick={() => onSort('name')}>Name</TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sortConfig.direction}>
                <TableSortLabel active={sortConfig.key === 'description'} direction={sortConfig.direction} onClick={() => onSort('description')}>Description</TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sortConfig.direction}>
                <TableSortLabel active={sortConfig.key === 'type'} direction={sortConfig.direction} onClick={() => onSort('type')}>Type</TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sortConfig.direction}>
                <TableSortLabel active={sortConfig.key === 'mechanics'} direction={sortConfig.direction} onClick={() => onSort('mechanics')}>Mechanics</TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sortConfig.direction}>
                <TableSortLabel active={sortConfig.key === 'primary_muscle'} direction={sortConfig.direction} onClick={() => onSort('primary_muscle')}>Primary Muscle</TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sortConfig.direction}>
                <TableSortLabel active={sortConfig.key === 'secondary_muscles'} direction={sortConfig.direction} onClick={() => onSort('secondary_muscles')}>Secondary Muscles</TableSortLabel>
              </TableCell>
              { selectedClient && <TableCell>
                <TableSortLabel>Target</TableSortLabel>
              </TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedExercises.map((exercise) => (
              <TableRow
                key={exercise.id}
              >
                { decodedToken.role === 'admin' && <TableCell>
                  {exercise.workout_count === 0 && <IconButton onClick={() => { setActiveExercise(exercise.id); openDialog(true) }} sx={{ position: 'relative', top: 0, right: 0 }} size="small">
                    <DeleteIcon />
                  </IconButton>}
                </TableCell>}
                <TableCell>{exercise.name}</TableCell>
                <TableCell>{exercise.description}</TableCell>
                <TableCell>{exercise.type}</TableCell>
                <TableCell>{exercise.mechanics}</TableCell>
                <TableCell>{getMuscleList(exercise.primary_muscle_bitwise)}</TableCell>
                <TableCell>{getMuscleList(exercise.secondary_muscles_bitwise)}</TableCell>
                { selectedClient &&
                <TableCell>{exercise.target_weight}</TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
  )
}

ListExercises.propTypes = {
  coachId: PropTypes.number
}

export default ListExercises
