import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'

const ListCoaches = () => {
  const [coaches, setCoaches] = useState([])

  useEffect(() => {
    const token = localStorage.getItem('token')
    axios.get(process.env.REACT_APP_API_URL + '/coaches', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setCoaches(response.data)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }, [])

  return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Names</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Business Name</TableCell>
              <TableCell align="right">ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coaches.map((coach) => (
              <TableRow
                key={coach.id}
              >
                <TableCell component="th" scope="row">
                  {coach.name}
                </TableCell>
                <TableCell>{coach.email}</TableCell>
                <TableCell>{coach.business_name}</TableCell>
                <TableCell align="right">{coach.id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  )
}

export default ListCoaches
