import React, { useState, useEffect } from 'react'
import { Box, Badge, Dialog, DialogTitle, DialogActions, Button } from '@mui/material'
import PropTypes from 'prop-types'
import axios from 'axios'

const getColourBasedOnValues = (activated, complete) => {
  if (activated || complete) return 'primary.main'
  return '#DEDEDE'
}

const shapeStyles = {
  width: 60,
  height: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  cursor: 'pointer',
  userSelect: 'none'
}
const shapeCircleStyles = { borderRadius: '50%', marginRight: '5px' }
const circle = (set, complete) => (
  <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles, bgcolor: getColourBasedOnValues(set.activated, complete) }}>{set.remaining_reps}</Box>
)

const WorkoutSets = ({ workoutId, workoutDay, repCount, setCount, exerciseId, userId, weight, workoutType, complete }) => {
  const [reps, setReps] = useState([])
  const [timerId, setTimerId] = useState(null)
  const [countdownTimerId, setCountdownTimerId] = useState(null)
  const [openSetCompleteDialog, setOpenSetCompleteDialog] = useState(false)
  const [openExerciseCompleteDialog, setExerciseSetCompleteDialog] = useState(false)
  const [countdown, setCountdown] = useState(90)

  useEffect(() => {
    if (repCount > 0 && setCount > 0) {
      const initialReps = Array.from({ length: setCount }, () => ({
        initial_reps: repCount,
        remaining_reps: repCount,
        activated: false
      }))
      setReps(initialReps)
    }
  }, [workoutDay, repCount, setCount])

  const handleSaveExerciseProgress = () => {
    setOpenSetCompleteDialog(false)
    setExerciseSetCompleteDialog(false)

    const token = localStorage.getItem('token')
    axios.post(process.env.REACT_APP_API_URL + '/workout/' + workoutId + '/progress', {
      userId,
      workoutDay,
      exerciseId,
      repCount,
      setCount,
      weight,
      workoutType
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        // console.log(response)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }

  const handleDialogClose = () => {
    setOpenSetCompleteDialog(false)
    setExerciseSetCompleteDialog(false)
    setCountdown(90)
    if (timerId) {
      clearTimeout(timerId)
    }
    if (countdownTimerId) {
      clearTimeout(countdownTimerId)
    }
  }

  const checkSetComplete = (newReps, setIndex) => {
    if (newReps[setIndex].activated) {
      const allSetsComplete = newReps.every(set => set.remaining_reps === set.initial_reps && set.activated === true)
      if (allSetsComplete) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  const showCongratulationsDialog = (newReps, setIndex, restPeriod) => {
    const isComplete = checkSetComplete(newReps, setIndex)
    if (isComplete) {
      setExerciseSetCompleteDialog(true)
    } else {
      setOpenSetCompleteDialog(true)
      setCountdown(restPeriod)
      const countdownTimer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownTimer)
            handleDialogClose()
          }
          return prevCountdown - 1
        })
      }, 1000)
      setCountdownTimerId(countdownTimer)
    }
  }

  const processSetClick = (setIndex) => {
    if (timerId) {
      clearTimeout(timerId)
    }
    if (countdownTimerId) {
      clearTimeout(countdownTimerId)
    }
    const newReps = JSON.parse(JSON.stringify(reps))

    if (newReps[setIndex].activated === true) {
      if (newReps[setIndex].remaining_reps === 0) {
        newReps[setIndex].remaining_reps = newReps[setIndex].initial_reps
        newReps[setIndex].activated = false
      } else {
        newReps[setIndex].remaining_reps -= 1
      }
    } else {
      newReps[setIndex].activated = true
    }

    setReps(newReps)
    let restPeriod = 90
    if (newReps[setIndex].remaining_reps < newReps[setIndex].initial_reps) {
      restPeriod = 180
    }
    const newTimerId = setTimeout(() => showCongratulationsDialog(newReps, setIndex, restPeriod), 1000)
    setTimerId(newTimerId)
  }

  return (
    <Box sx={{ padding: '0px', marginBottom: '5px' }}>
      {complete === true && reps.map((set, index) => (
        <Badge invisible key={index} color="primary" badgeContent={set.remaining_reps} variant="dot">
          {circle(set, complete)}
        </Badge>
      ))}
      {complete === false && reps.map((set, index) => (
        <Badge invisible onClick={() => processSetClick(index)} key={index} color="primary" badgeContent={set.remaining_reps} variant="dot">
          {circle(set, complete)}
        </Badge>
      ))}
      <Dialog open={openSetCompleteDialog} onClose={handleDialogClose}>
        <DialogTitle>Well Done! Take a rest.</DialogTitle>
        <DialogActions>
          <Button onClick={handleDialogClose}>OK ({countdown}s)</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openExerciseCompleteDialog} onClose={handleDialogClose}>
        <DialogTitle>Exercise Completed!</DialogTitle>
        <DialogActions>
          <Button onClick={handleSaveExerciseProgress}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

WorkoutSets.propTypes = {
  workoutId: PropTypes.number,
  workoutDay: PropTypes.number,
  repCount: PropTypes.number,
  setCount: PropTypes.number,
  exerciseId: PropTypes.number,
  userId: PropTypes.number,
  weight: PropTypes.number,
  workoutType: PropTypes.number,
  complete: PropTypes.bool
}
export default WorkoutSets
