import React from 'react'
import { Box, Typography, IconButton, Tooltip } from '@mui/material'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import PropTypes from 'prop-types'
import Progress from '../components/Progress'
import Checkins from '../components/Checkins'

const CoachPortal = ({ coachId, username, businessName, logoUrl }) => {
  const referralLink = 'https://b6l75gwemft.typeform.com/to/ajCfEhwj#coach_id=' + coachId
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
  }

  return (
    <Box sx={{ textAlign: 'left', padding: '20px', mx: 'auto', whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
      <Typography sx={{ padding: '0px' }}variant="h4">
                Hi, {username}!
      </Typography>
      <Typography sx={{ padding: '0px' }}variant="body1">
                This is your portal. In here you can manage all your clients, meals & workouts.
      </Typography>
      <Typography sx={{ padding: '0px' }}variant="body1">
                Your unique signup link is:
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ overflowX: 'scroll' }}>
          <code style={{ marginRight: '10px', whiteSpace: 'break-spaces', overflow: 'hidden' }}>
            <Tooltip title="Copy to clipboard">
              <IconButton onClick={() => copyToClipboard(referralLink)}>
                <FileCopyIcon />
              </IconButton>
            </Tooltip><a rel="noreferrer" target="_blank" href={referralLink}>{referralLink}</a>
          </code></div>
      </Box>
      <Typography sx={{ padding: '0px', paddingBottom: '20px' }}variant="h5">
        Client Checkins
      </Typography>
      <Box>
        <Checkins />
      </Box>
      <Typography sx={{ padding: '0px' }}variant="h5">
        Client Progress
      </Typography>
      <Box>
        <Progress />
      </Box>
    </Box>
  )
}

CoachPortal.propTypes = {
  coachId: PropTypes.number,
  username: PropTypes.string,
  businessName: PropTypes.string,
  logoUrl: PropTypes.string
}

export default CoachPortal
