import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import ListMeals from '../../components/ListMeals'
import PropTypes from 'prop-types'

const CoachListMeals = ({ coachId }) => {
  useEffect(() => {
    console.log(coachId)
  }, [])

  return (
    <Box sx={{ textAlign: 'left', padding: '20px', mx: 'auto', whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
      <Typography sx={{ padding: '0px' }}variant="h4">
                Coach Meals
                <ListMeals coachId={coachId}/>
      </Typography>
      <Typography sx={{ padding: '0px' }}variant="h4">
                bdvoted Meals
                <ListMeals coachId={'0'} />
      </Typography>
    </Box>
  )
}

CoachListMeals.propTypes = {
  coachId: PropTypes.number
}

export default CoachListMeals
