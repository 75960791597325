import * as React from 'react'
import { useRoutes } from 'react-router-dom'
import AddMeal from '../components/AddMeal'
import ListMeals from '../components/ListMeals'
import ListMealPlans from '../components/ListMealPlans'
import AddMealPlan from '../components/AddMealPlan'

const Meals = () => {
  const routes = useRoutes([
    { path: '/', element: <ListMeals /> },
    { path: 'add', element: <AddMeal /> },
    { path: 'plans', element: <ListMealPlans /> },
    { path: 'plans/add', element: <AddMealPlan /> }
  ])

  return (
    <div>
      <main>
        {routes}
      </main>
    </div>
  )
}

export default Meals
