import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, Button, Paper, Typography } from '@mui/material'
import WorkoutSets from '../components/workout/WorkoutSets'
import PropTypes from 'prop-types'
import axios from 'axios'

function extractNumericValue (str) {
  const converted = str.toString()
  const matches = converted.match(/(\d+(\.\d+)?)/)
  return matches ? parseFloat(matches[0]) : 0
}

const ClientWorkouts = ({ client, workout, userId, username, currentWorkoutDay, updateWorkoutDay }) => {
  const [workoutDay, setWorkoutDay] = useState(currentWorkoutDay)
  const [exercises, setExercises] = useState(false)
  const [newWeight, setNewWeight] = useState(0)
  const [sortGroup, setSortGroup] = useState(0)
  const [exerciseIndex, setExerciseIndex] = useState(0)

  const [isModalOpen, setModalOpen] = useState(false)
  const [dayCompleteOpen, setDayCompleteOpen] = useState(false)

  const handleOpenModal = (sortGroup, id) => {
    setSortGroup(sortGroup)
    setExerciseIndex(id)
    setNewWeight(exercises.sort_groups[sortGroup].exercises[id].target_weight)
    setModalOpen(true)
  }

  const updateWeight = () => {
    const updatedExercises = JSON.parse(JSON.stringify(exercises))
    updatedExercises.sort_groups[sortGroup].exercises[exerciseIndex].target_weight = newWeight
    setExercises(updatedExercises)
    setModalOpen(false)
  }

  useEffect(() => {
    setWorkoutDay(currentWorkoutDay)
  }, [currentWorkoutDay])

  useEffect(() => {
    if (workout && workout.exercises) {
      setExercises(workout.exercises[workoutDay - 1])
    }
  }, [workout, client, userId, username, workoutDay])

  const nextDay = () => {
    setDayCompleteOpen(true)
    setWorkoutDay((prevWorkoutDay) => {
      let updatedDay = prevWorkoutDay + 1
      if (prevWorkoutDay >= workout.exercises.length) {
        updatedDay = 1
      }

      const token = localStorage.getItem('token')
      axios.put(process.env.REACT_APP_API_URL + '/clients/' + userId, {
        workout_day: parseInt(updatedDay)
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        // console.log(response)
      }).catch(error => {
        console.error('There was an error!', error)
      })
      updateWorkoutDay(updatedDay)
      return updatedDay
    })
  }

  return (
    <Box sx={{ textAlign: 'left', padding: '20px', mx: 'auto', whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
      <Dialog open={dayCompleteOpen} onClose={() => setDayCompleteOpen(false)}>
        <DialogTitle>Well done!</DialogTitle>
        <DialogContent>
          We&apos;ve moved you onto the next day, ready for next time!
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDayCompleteOpen(false)}>Done</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isModalOpen} keepMounted onClose={() => setModalOpen(false)}>
        <DialogContent>
          <TextField InputLabelProps={{ shrink: true }} label="Adjust Weight" value={newWeight} onChange={(e) => setNewWeight(e.target.value)} />
          <Button variant="contained" color="primary" onClick={() => updateWeight()}>
            Update Weight
          </Button>
        </DialogContent>
      </Dialog>
      <Typography sx={{ marginBottom: '10px' }} variant="h4">
        Day {workoutDay}
      </Typography>
      <Button type="submit" variant="contained" color="primary" onClick={() => nextDay()}>Mark Workout Complete</Button>
      <div style={{ paddingTop: '20px' }}>
      {exercises && exercises.sort_groups.map((groups, index) => (
        <Paper key={index} sx={{ padding: '10px', marginBottom: '10px' }}>
          <Typography variant="h6">
            {groups.muscle_groups}{groups.superset && ' (Superset)'}
          </Typography>
          {groups.exercises && groups.exercises.length > 0 && groups.exercises.map((exercise, indexx) => (
            <div key={indexx} >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                <Typography variant="h7">
                  {exercise.name}
                </Typography>
                <Typography variant="h7" style={{ textAlign: 'right', cursor: 'pointer' }} onClick={() => handleOpenModal(index, indexx)}>
                  {exercise.target_weight}kg
                </Typography>
              </div>
              <WorkoutSets workoutType={exercise.workout_type} weight={extractNumericValue(exercise.target_weight)} workoutId={workout.id} workoutDay={workoutDay} setCount={exercise.sets} repCount={exercise.reps} exerciseId={exercise.id} userId={userId} complete={exercise.progress && exercise.progress.complete } />
            </div>
          ))}
        </Paper>
      ))}
      </div>
    </Box>
  )
}

ClientWorkouts.propTypes = {
  userId: PropTypes.number,
  username: PropTypes.string,
  client: PropTypes.object,
  workout: PropTypes.object,
  currentWorkoutDay: PropTypes.number,
  updateWorkoutDay: PropTypes.func
}
export default ClientWorkouts
