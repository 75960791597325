import React, { useEffect } from 'react'
// import { useNavigate, useLocation } from 'react-router-dom'
import './FixedFooter.scss'

const FixedFooter = () => {
  // const navigate = useNavigate()
  useEffect(() => {
  }, [])

  return (
    <div className='fixedFooterWrapper'>
        <div className='fixedFooterNavBar'>
            powered by bdvoted &copy;
        </div>
    </div>
  )
}

export default FixedFooter
