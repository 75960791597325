import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'

const ClientMeasurements = () => {
  useEffect(() => {
  }, [])
  return (
    <Box sx={{ mx: 'auto' }}>
        <Typography sx={{ padding: '10px' }}variant="h4">
            Measurements
        </Typography>
        <Box sx={{ textAlign: 'center', padding: '20px', mx: 'auto', whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
          Coming soon!
        </Box>
    </Box>
  )
}

export default ClientMeasurements
