import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import EditWorkout from '../../components/EditWorkout'
import PropTypes from 'prop-types'

const CoachEditWorkout = ({ coachId }) => {
  useEffect(() => {
  }, [])

  return (
    <Box sx={{ textAlign: 'left', padding: '20px', mx: 'auto', whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
      <Typography sx={{ padding: '0px' }}variant="h4">
        Edit Workout
      </Typography>
      <EditWorkout coachId={coachId} />
    </Box>
  )
}

CoachEditWorkout.propTypes = {
  coachId: PropTypes.number
}

export default CoachEditWorkout
