import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const ClientStats = ({ weight, height, hip, neck, waist, gender, goal, exerciseCalories, updateCalorieGoal, updateProteinGoal }) => {
  // const [bodyFatPercentage, setBodyFatPercentage] = useState(18);
  const [bmr, setBmr] = useState(0)
  const [tdee, setTdee] = useState(0)
  const [targetCalories, setTargetCalories] = useState()
  const [dailySurplus, setDailySurplus] = useState()
  const [proteinGoal, setProteinGoal] = useState()

  useEffect(() => {
    if (!weight) return

    const isMetric = true
    let isMale = true

    if (hip > 0) {
      isMale = false
    }

    console.log(gender)
    const weightInKg = isMetric ? weight : weight / 2.20462
    const heightInCm = isMetric ? height : height * 2.54
    // const heightInM = isMetric ? height / 100 : height * 0.0254;
    const neckInCm = isMetric ? neck : neck * 2.54
    const hipInCm = isMetric ? hip : hip * 2.54
    const waistInCm = isMetric ? waist : waist * 2.54

    let bodyFat

    if (isMale) {
      // Formula for men
      bodyFat = 495 / (1.0324 - 0.19077 * Math.log10(waistInCm - neckInCm) + 0.15456 * Math.log10(heightInCm)) - 450
    } else {
      // Formula for women
      bodyFat = 495 / (1.29579 - 0.35004 * Math.log10(waistInCm + hipInCm - neckInCm) + 0.22100 * Math.log10(heightInCm)) - 450
    }
    // setBodyFatPercentage(bodyFat.toFixed(2));

    const leanBodyMass = weightInKg * (1 - bodyFat / 100)
    const bmr = 370 + (21.6 * leanBodyMass)

    setBmr(bmr.toFixed(2))

    const totalDailyEnergyExpenditure = Number(bmr) + Number(exerciseCalories)

    setTdee(totalDailyEnergyExpenditure)

    let surplus = 0
    if (goal === 'maintain') {
      setDailySurplus(0)
      setTargetCalories(totalDailyEnergyExpenditure.toFixed(2))
    }

    if (goal === 'lose') {
      surplus = Math.round(Number(bmr) * -0.15)
      setDailySurplus(surplus)
    }

    if (goal === 'gain') {
      surplus = Math.round(Number(bmr) * 0.2)
      setDailySurplus(surplus)
    }

    setTargetCalories((totalDailyEnergyExpenditure + surplus).toFixed(2))
    updateCalorieGoal((totalDailyEnergyExpenditure + surplus).toFixed(2))

    const protein = Math.round(leanBodyMass * 2.20462) + 'g'
    setProteinGoal(protein)
    updateProteinGoal(protein)
  }, [weight, height, hip, neck, waist, gender, goal, exerciseCalories])

  return (
    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>BMR</TableCell>
              <TableCell>TDEE</TableCell>
              <TableCell>{dailySurplus < 0 ? 'Daily Deficit' : 'Daily Surplus'}</TableCell>
              <TableCell>Daily kCal</TableCell>
              <TableCell>Weekly kCal</TableCell>
              <TableCell>Protein Goal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">{Math.round(bmr)}</TableCell>
                <TableCell component="th" scope="row">{Math.round(tdee)}</TableCell>
                <TableCell component="th" scope="row">{dailySurplus < 0 ? dailySurplus * -1 : dailySurplus}</TableCell>
                <TableCell component="th" scope="row">{Math.round(targetCalories)}</TableCell>
                <TableCell component="th" scope="row">{Math.round(targetCalories * 7)}</TableCell>
                <TableCell component="th" scope="row">{proteinGoal}</TableCell>
              </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
  )
}

ClientStats.propTypes = {
  weight: PropTypes.string,
  height: PropTypes.string,
  hip: PropTypes.string,
  neck: PropTypes.string,
  waist: PropTypes.string,
  gender: PropTypes.string,
  goal: PropTypes.string,
  exerciseCalories: PropTypes.string,
  updateCalorieGoal: PropTypes.func,
  updateProteinGoal: PropTypes.func
}

export default ClientStats
