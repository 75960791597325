import { Box, Button, Dialog, DialogContent, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'

const Progress = ({ clientId }) => {
  const [analysis, setAnalysis] = useState('')
  const [open, setOpen] = useState(false)

  const handleSubmit = () => {
    setOpen(true)
    const token = localStorage.getItem('token')
    axios.get(process.env.REACT_APP_API_URL + '/v1/analyze-client/' + clientId, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      console.log(response.data.message)
      setAnalysis(response.data.message)
      setOpen(false)
    }).catch(error => {
      console.error('There was an error!', error)
      setAnalysis('Apologies, there has been an error')
      setOpen(false)
    })
  }

  return (
    <Box sx={{ textAlign: 'left', padding: '20px', mx: 'auto', whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
      <Button onClick={() => handleSubmit()} variant="contained" color="primary">Analyze Client</Button>
      <div>
        {analysis}
      </div>
      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
      >
        <DialogContent>
          <p>Analyzing using AI. This can take up to 2 minutes - usually quicker.</p>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </Box>

  )
}

Progress.propTypes = {
  clientId: PropTypes.number
}
export default Progress
