import * as React from 'react'
import { useRoutes } from 'react-router-dom'
import AddClient from '../components/AddClient'
import ListClients from '../components/ListClients'
import EditClient from '../components/EditClient'

const Clients = () => {
  const routes = useRoutes([
    { path: '/', element: <ListClients /> },
    { path: 'add', element: <AddClient /> },
    { path: ':id/edit', element: <EditClient /> }
  ])

  return (
    <div>
      {routes}
    </div>
  )
}

export default Clients
