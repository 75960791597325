import React, { useEffect, useState } from 'react'
import { Box, Paper, TextField, TableCell, Typography, Table, TableBody, TableContainer, TableRow, Checkbox, FormControlLabel } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Cancel from '@mui/icons-material/Cancel'
import AddIcon from '@mui/icons-material/Add'
import PropTypes from 'prop-types'

const WorkoutDay = ({ exercises, edit, day, allExercises = [], updateWorkout }) => {
  const [dayExercises, setDayExercises] = useState(exercises)
  const [allExerciseOptions, setAllExerciseOptions] = useState([])

  useEffect(() => {
    const tmp = allExercises.map((exercise) => ({
      label: exercise.name,
      id: exercise.id
    }))
    setAllExerciseOptions(tmp)
  }, [allExercises])

  useEffect(() => {
    if (updateWorkout) {
      updateWorkout(day, dayExercises)
    }
  }, [dayExercises])

  useEffect(() => {
    if (edit === false && (JSON.stringify(exercises) !== JSON.stringify(dayExercises))) {
      console.log('exercises change')
      setDayExercises(exercises)
    }
  }, [exercises, dayExercises])

  const handleExerciseChange = (exerciseId, set, exerciseNumber) => {
    const updatedExercises = JSON.parse(JSON.stringify(exercises))
    updatedExercises[set].exercises[exerciseNumber].id = exerciseId
    setDayExercises(updatedExercises)
  }

  const handleSetsChange = (event, set, exerciseNumber) => {
    const updatedExercises = JSON.parse(JSON.stringify(exercises))
    updatedExercises[set].exercises[exerciseNumber].sets = parseInt(event.target.value)
    setDayExercises(updatedExercises)
  }

  const handleRepsChange = (event, set, exerciseNumber) => {
    const updatedExercises = JSON.parse(JSON.stringify(exercises))
    updatedExercises[set].exercises[exerciseNumber].reps = parseInt(event.target.value)
    setDayExercises(updatedExercises)
  }

  const handleSupersetChange = (event, set) => {
    const updatedExercises = JSON.parse(JSON.stringify(exercises))
    updatedExercises[set].superset = event.target.checked
    setDayExercises(updatedExercises)
  }

  const removeExercise = (set, exerciseNumber) => {
    const updatedExercises = JSON.parse(JSON.stringify(exercises))
    updatedExercises[set].exercises.splice(exerciseNumber, 1)
    setDayExercises(updatedExercises)
  }

  const addExercise = (set, exerciseNumber) => {
    const updatedExercises = JSON.parse(JSON.stringify(exercises))
    const exercise = {
      id: 1,
      reps: 8,
      sets: 3
    }
    updatedExercises[set].exercises.push(exercise)
    setDayExercises(updatedExercises)
  }

  const addGroup = () => {
    console.log('click')
    const updatedExercises = JSON.parse(JSON.stringify(exercises))
    const group = {
      exercises: [],
      muscle_groups: 'PlaceHolder',
      superset: false,
      sort: exercises.length + 1
    }
    updatedExercises.push(group)
    setDayExercises(updatedExercises)
  }

  const removeGroup = (groupid) => {
    console.log('click')
    const updatedExercises = JSON.parse(JSON.stringify(exercises))
    updatedExercises.splice(groupid, 1)
    setDayExercises(updatedExercises)
  }

  return (
    <Box sx={{ padding: '0px', marginBottom: '20px' }}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
          {dayExercises && dayExercises.length > 0 && dayExercises.map((groups, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography sx={{ padding: '0px' }} variant='h6'>
                  {edit && <Cancel onClick={() => removeGroup(index)} color="error" />}{groups.muscle_groups}{edit === 'false' && groups.superset && ' (superset)'}{edit && <FormControlLabel control={<Checkbox onChange={(e) => handleSupersetChange(e, index)} checked={!!groups.superset} />} label="Superset?" />}
                  </Typography>
                </TableCell>
              </TableRow>
              {groups.exercises && groups.exercises.length > 0 && groups.exercises.map((exercise, indexx) => (
                <TableRow key={indexx}>
                  {edit && <TableCell sx={{ width: '50px' }}>
                    <Cancel onClick={() => removeExercise(index, indexx)} color="error" />
                  </TableCell>}
                  <TableCell sx={{ width: '200px' }}>
                      {edit && <Autocomplete
                      id="exercise-autocomplete"
                      options={allExerciseOptions}
                      getOptionLabel={(option) => option.label}
                      value={allExerciseOptions.find(ex => ex.id === exercise.id) || null}
                      onChange={(event, newValue) => {
                        handleExerciseChange(newValue ? newValue.id : '', index, indexx)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Exercise"
                          variant="outlined"
                          fullWidth
                          sx={{ marginBottom: '20px' }}
                        />
                      )}
                    />}{!edit && exercise.name}{groups.superset && ((indexx + 1) < groups.exercises.length) && ' (10s rest)'}
                  </TableCell>
                  <TableCell sx={{ width: '100px' }}>
                      {edit && <div><TextField
                        id="exercise-sets"
                        label="Sets"
                        type="number"
                        value={exercise.sets}
                        onChange={(e) => handleSetsChange(e, index, indexx)}
                  /><TextField
                  id="exercise-reps"
                  label="Reps"
                  type="number"
                  value={exercise.reps}
                  onChange={(e) => handleRepsChange(e, index, indexx)}
            /></div>}{!edit && (exercise.sets + 'x' + exercise.reps)}
                  </TableCell>
                  <TableCell>
                    {exercise.target_weight}
                  </TableCell>
                </TableRow>
              ))}
              {edit && <TableRow>
                <TableCell sx={{ width: '50px' }}>
                    <AddIcon onClick={() => addExercise(index, groups.exercises.length)} />
                  </TableCell><TableCell>Add Exercise</TableCell></TableRow>}
            </React.Fragment>
          ))}
          {edit && <TableRow>
            <TableCell sx={{ width: '50px' }}>
                <AddIcon onClick={() => addGroup()} />
              </TableCell><TableCell>Add Exercise Group</TableCell></TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

WorkoutDay.propTypes = {
  exercises: PropTypes.array,
  edit: PropTypes.bool,
  day: PropTypes.number,
  allExercises: PropTypes.array,
  updateWorkout: PropTypes.func
}

export default WorkoutDay
