import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import AddMeal from '../../components/AddMeal'

const CoachAddMeal = () => {
  useEffect(() => {
  }, [])

  return (
    <Box sx={{ textAlign: 'left', padding: '20px', mx: 'auto', whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
      <Typography sx={{ padding: '0px' }}variant="h4">
        Add Meal
        <AddMeal />
      </Typography>
    </Box>
  )
}

export default CoachAddMeal
