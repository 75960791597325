import { Box, Button, Typography, Select, MenuItem, FormControl, InputLabel, Dialog, DialogActions, DialogContent, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import WorkoutDay from './workout/WorkoutDay'
import PropTypes from 'prop-types'
import { decodeToken } from 'react-jwt'

const ViewWorkout = ({ workoutId, coachId }) => {
  const [name, setName] = useState('')
  const [clients, setClients] = useState([])
  const [workout, setWorkout] = useState(null)
  const [selectedClient, setSelectedClient] = useState('')
  const [considerations, setConsiderations] = useState('')
  const [decodedToken, setDecodedToken] = useState({ role: '' })
  const [open, setOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState(false)
  const [saved, setSaved] = useState(false)

  const handleClientChange = (event) => {
    setSelectedClient(event.target.value)
    setConsiderations('')
    /*
    const token = localStorage.getItem('token')
    const url = `${process.env.REACT_APP_API_URL}/v1/analyze-client-workout?client_id=${event.target.value}&workout_id=${workoutId}`
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        const cons = response.data
        setConsiderations(cons.message)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
      */
  }

  const closeDialog = () => {
    setOpen(false)
    setSaved(false)
  }

  const assignWorkout = (event) => {
    event.preventDefault()
    setDialogMessage('Saving')
    setOpen(true)
    const token = localStorage.getItem('token')
    axios.put(process.env.REACT_APP_API_URL + '/clients/' + selectedClient, {
      workout_id: parseInt(workoutId)
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      console.log(response)
      setSaved(true)
      setDialogMessage('Saved')
    }).catch(error => {
      console.error('There was an error!', error)
    })
  }

  useEffect(() => {
    if (workout) {
      setName(workout.name)
    }
  }, [workout])

  useEffect(() => {
    const token = localStorage.getItem('token')
    const url = `${process.env.REACT_APP_API_URL}/workout/${workoutId}?client_id=${selectedClient}`
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        const workoutReponse = response.data
        setWorkout(workoutReponse)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }, [selectedClient])

  useEffect(() => {
    const token = localStorage.getItem('token')
    setDecodedToken(decodeToken(token))
    if (coachId) {
      axios.get(process.env.REACT_APP_API_URL + '/clients?coach_id=' + coachId, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          const clientResponse = response.data
          setClients(clientResponse)
        })
        .catch(error => {
          console.error('There was an error!', error)
        })
    }
  }, [])

  return (
    <Box sx={{ textAlign: 'left', mx: 'auto', paddingTop: '10px' }}>
      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
      >
        <DialogContent>
          <p>{dialogMessage}</p>
          {!saved && <CircularProgress />}
        </DialogContent>
        {saved &&
        <DialogActions>
          <Button variant="contained" color="primary" onClick={closeDialog}>Close</Button>
        </DialogActions>}
      </Dialog>
      {((workout && coachId === workout.coach_id) || decodedToken.role === 'admin') && <Link to={`/coaches/workouts/${workoutId}/edit`}>Edit Workout</Link>}
      <Typography sx={{ padding: '0px' }}variant='h4'>
        {name}
      </Typography>
      {coachId &&
      <FormControl fullWidth variant='outlined' sx={{ marginBottom: '20px' }}>
        <InputLabel id='client-select-label'>Select Client</InputLabel>
        <Select
          labelId='client-select-label'
          id='client-select'
          value={selectedClient}
          onChange={handleClientChange}
          label='Select Client'
        >
          {clients && clients.length > 0 && clients.map((client, index) => (
            <MenuItem key={index} value={client.id}>{client.name}</MenuItem>
          ))}
        </Select>
        <Button onClick={assignWorkout} variant='contained' color='primary'>Assign Workout</Button>
      </FormControl>}
      {considerations}
      {workout && workout.exercises && workout.exercises.length > 0 && workout.exercises.map((exercises, index) => (
        <div key={index}>
          <Typography sx={{ padding: '0px', marginBottom: '0px' }}variant='h5'>
            Day {exercises.day_number}
          </Typography>
          {exercises && exercises.length} {
            <WorkoutDay edit={false} key={index} day={index + 1} exercises={exercises.sort_groups} />
          }
        </div>
      ))}
    </Box>
  )
}

ViewWorkout.propTypes = {
  coachId: PropTypes.number,
  workoutId: PropTypes.number
}

export default ViewWorkout
