import { Grid, Dialog, DialogContent } from '@mui/material'
import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ClientStats from './ClientStats'

const ViewClient = () => {
  const [clientId, setClientId] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [photo, setPhoto] = useState('')
  const [height, setHeight] = useState('')
  const [weight, setWeight] = useState('')
  const [waist, setWaist] = useState('')
  const [checkins, setCheckins] = useState('')
  const [lastCheckinDate, setLastCheckinDate] = useState('')
  const [hip, setHip] = useState('')
  const [neck, setNeck] = useState('')
  const [gender, setGender] = useState('')
  const [dietaryProfile, setDietaryProfile] = useState('')
  const [goal, setGoal] = useState('maintain')
  const [coachId, setCoachId] = useState('-1')
  const [exerciseCalories, setExerciseCalories] = useState('0')
  const [calorieGoal, setCalorieGoal] = useState('0')
  const [proteinGoal, setProteinGoal] = useState('0')

  const { id } = useParams()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (checkins[0]) {
      setLastCheckinDate(checkins[0].checkin_date)
    }
  })

  useEffect(() => {
    setOpen(true)
    const token = localStorage.getItem('token')
    console.log(clientId)

    axios.get(process.env.REACT_APP_API_URL + '/clients/' + id, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        const client = response.data // extract the first client from the response
        setClientId(client.id)
        setName(client.name)
        setEmail(client.email)
        setHeight(parseFloat(client.height))
        setPhoto(client.starting_front_photo)
        setWeight(parseFloat(client.weight))
        setWaist(parseFloat(client.waist))
        setHip(parseFloat(client.hip))
        setNeck(parseFloat(client.neck))
        setDietaryProfile(client.dietary_profile)
        setGender(client.gender)
        setCoachId(client.coach_id)
        setGoal(client.goal)
        setExerciseCalories(client.exercise_calories)
        setCheckins(client.checkins)
        setOpen(false)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }, [id])

  return (
    <Grid container spacing={2} sx={{ border: '0px solid red;' }}>
        <Dialog
            open={open}
            keepMounted
            onClose={() => setOpen(false)}
        >
            <DialogContent>
            Saving/Loading...
            </DialogContent>
        </Dialog>
        <Grid item xs={14}>
          <h2>{name}</h2>
        </Grid>
        <Grid item >
          <ClientStats exerciseCalories={exerciseCalories} goal={goal} height={height} weight={weight} waist={waist} hip={hip} neck={neck} gender={gender} updateCalorieGoal={setCalorieGoal} updateProteinGoal={setProteinGoal} />
          <div>
          {name}
          {email}
          {dietaryProfile}
          {coachId}
          {calorieGoal}
          {proteinGoal}
          </div>
        </Grid>
        <Grid item xs={2}>
          <img width="150px" src={photo} />
          Height<br />
          {height}<br />
          Weight<br />
          {weight}<br />
          CheckIn<br />
          {lastCheckinDate}
        </Grid>
    </Grid>
  )
}

export default ViewClient
