import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { decodeToken } from 'react-jwt'

const ClientSignup = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  const decodedToken = decodeToken(token)
  const [businessName, setBusinessName] = useState([])
  const [coachName, setCoachName] = useState([])

  useEffect(() => {
    if (decodedToken && decodedToken.businessName) {
      setBusinessName(decodedToken.businessName)
      setCoachName(decodedToken.coachName)
    }
  }, [])

  return (
    <Box sx={{ mx: 'auto' }}>
      {businessName.length > 0
        ? <div>
          <Typography sx={{ padding: '10px' }} variant="h4">
                    Client Signup for {businessName}
          </Typography>
          <Typography sx={{ padding: '10px' }} variant="p">
            {coachName} has invited you. Fill out the details below.
          </Typography>
        </div>
        : <header className="App-header">
                    Sorry! Direct signups are currently closed. You will need to be invited. If you have already been invited please use the link provided by your Personal Trainer.
        </header>
      }
    </Box>
  )
}

export default ClientSignup
