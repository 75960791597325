import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import { Link } from 'react-router-dom'

const ListClients = () => {
  const [clients, setClients] = useState([])

  useEffect(() => {
    const token = localStorage.getItem('token')
    axios.get(process.env.REACT_APP_API_URL + '/clients', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setClients(response.data)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }, [])

  return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Last Checkin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => (
              <TableRow key={client.id}>
                <TableCell component="th" scope="row">{client.id}</TableCell>
                <TableCell><Link to={`/coaches/clients/${client.id}/edit`}>{client.name}</Link></TableCell>
                <TableCell>{client.email}</TableCell>
                <TableCell>{client.last_checkin}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  )
}

export default ListClients
