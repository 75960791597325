import { Box, Button, TextField, Grid, Dialog, DialogContent } from '@mui/material'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { MUSCLE_LIST } from '../MuscleList'

const AddExercise = () => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [primaryMuscles, setPrimaryMuscles] = useState('')
  const [secondaryMuscles, setSecondaryMuscles] = useState('')
  const [type, setType] = useState('')
  const [mechanics, setMechanics] = useState('')
  const [bulkInput, setBulkInput] = useState('')
  const [bufferedExercises, setBufferedExercises] = useState([])
  const [readyExercises, setReadyExercises] = useState([])
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0)
  const [existingExercises, setExistingExercises] = useState([])
  const [nameWarning, setNameWarning] = useState('')
  const [isNextExerciseDisabled, setIsNextExerciseDisabled] = useState(false)

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (bufferedExercises.length > 0) {
      processExercises()
    }
  }, [bufferedExercises])

  const processBulkInput = () => {
    setIsNextExerciseDisabled(true)
    const exercises = bulkInput.split('\n')
    setBufferedExercises(exercises)
    setCurrentExerciseIndex(0)
  }

  const populateNextExercise = () => {
    const nextExercise = readyExercises[currentExerciseIndex]
    setName(nextExercise.name)
    setDescription(nextExercise.description)
    setPrimaryMuscles(nextExercise.primary_muscle_bitwise)
    setSecondaryMuscles(nextExercise.secondary_muscle_bitwise)
    setType(nextExercise.type)
    setMechanics(nextExercise.mechanics)
    if (currentExerciseIndex < bufferedExercises.length - 1) {
      setCurrentExerciseIndex(currentExerciseIndex + 1)
    } else {
      setCurrentExerciseIndex(0)
    }
  }

  const processExercises = async () => {
    let tmpExercises = []
    const exercisePromises = bufferedExercises.map(exercise => generateExercise(exercise))

    try {
      tmpExercises = await Promise.all(exercisePromises)
      console.log(tmpExercises)
      setReadyExercises(tmpExercises)
      console.log('readyExercises', readyExercises)
    } catch (error) {
      console.error('Error processing exercises', error)
    } finally {
      setIsNextExerciseDisabled(false)
    }
  }

  const generateExercise = (exerciseName) => {
    const token = localStorage.getItem('token')
    return axios.post(process.env.REACT_APP_API_URL + '/generator', {
      fields: 'name, description, primary_muscle_bitwise, secondary_muscle_bitwise, type(Isolation or Compound), mechanics(Push or Pull or Static)',
      hint: 'Please find the singular bitwise primary muscle group, and also bitwise for the secondary muscle groups plural, for this exercise: ' + exerciseName + '. A description would be good too. The bitwise map is: ' + JSON.stringify(MUSCLE_LIST) + '. It is important to pay close attention to the detail of the bitwise map'
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      console.log(response.data)
      return response.data
    }).catch(error => {
      console.error('There was an error!', error)
      throw error
    })
  }

  const singleGenerateExercise = async () => {
    setOpen(true)
    try {
      const data = await generateExercise(name)
      setName(data.name)
      setDescription(data.description)
      setPrimaryMuscles(data.primary_muscle_bitwise)
      setSecondaryMuscles(data.secondary_muscle_bitwise)
      setType(data.type)
      setMechanics(data.mechanics)
    } catch (error) {
      console.error('There was an error!', error)
    }
    setOpen(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const token = localStorage.getItem('token')
    axios.post(process.env.REACT_APP_API_URL + '/exercises', {
      name,
      description,
      primary_muscle: primaryMuscles,
      secondary_muscles: secondaryMuscles,
      type,
      mechanics
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      console.log(response)
      setName('')
      setDescription('')
      setPrimaryMuscles('')
      setSecondaryMuscles('')
      setType('')
      setMechanics('')
    }).catch(error => {
      console.error('There was an error!', error)
    })
  }

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + '/exercises', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    })
      .then(response => {
        setExistingExercises(response.data)
      })
      .catch(error => {
        console.error('Error fetching exercises', error)
      })
  }, [])

  useEffect(() => {
    checkForSimilarName()
  }, [name, existingExercises])

  const checkForSimilarName = () => {
    const similarExercise = existingExercises.find(exercise =>
      exercise.name.toLowerCase().includes(name.toLowerCase()))

    if (similarExercise) {
      setNameWarning('A similar exercise name already exists.')
    } else {
      setNameWarning('')
    }
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mx: 'auto', maxWidth: 400, mt: 2 }}>
      <Grid item>
        <TextField
          label="Bulk Exercises Input"
          multiline
          rows={4}
          value={bulkInput}
          onChange={(e) => setBulkInput(e.target.value)}
        />
      </Grid>
      <Grid item>
        <Button disabled={isNextExerciseDisabled} onClick={processBulkInput} variant="contained" color="primary">Pre-Process Exercises</Button>
      </Grid>
      <Grid item>
        <Button disabled={isNextExerciseDisabled} onClick={populateNextExercise} variant="contained" color="primary">Next Exercise</Button>
      </Grid>
      <Grid container direction="column" spacing={2}>
        <Grid item>{nameWarning && <p style={{ color: 'red' }}>{nameWarning}</p>}</Grid>
        <Grid item><TextField label="Exercise Name" value={name} onChange={(e) => setName(e.target.value)} required /></Grid>
        <Grid item><TextField label="Description" value={description} onChange={(e) => setDescription(e.target.value)} required /></Grid>
        <Grid item><TextField label="Primary Muscle" value={primaryMuscles} onChange={(e) => setPrimaryMuscles(e.target.value)} required /></Grid>
        <Grid item><TextField label="Secondary Muscles" value={secondaryMuscles} onChange={(e) => setSecondaryMuscles(e.target.value)} /></Grid>
        <Grid item><TextField label="Type" value={type} onChange={(e) => setType(e.target.value)} /></Grid>
        <Grid item><TextField label="Mechanics" value={mechanics} onChange={(e) => setMechanics(e.target.value)} /></Grid>
        <Grid item><Button onClick={singleGenerateExercise} variant="contained" color="primary">Generate Exercise</Button></Grid>
        <Grid item><Button type="submit" variant="contained" color="primary">Add Exercise</Button></Grid>
      </Grid>
      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
      >
        <DialogContent>
            Generating Exercise ...
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default AddExercise
