import { Box, Button, TextField, Checkbox, Grid, Dialog, DialogContent } from '@mui/material'
import React, { useState } from 'react'
import axios from 'axios'

// import { DIETARY_PROFILES } from '../DietaryProfiles'

const AddMeal = () => {
  const [mealName, setMealName] = useState('')
  const [description, setDescription] = useState('')
  const [ingredients, setIngredients] = useState('')
  const [instructions, setInstructions] = useState('')
  const [calories, setCalories] = useState('')
  const [protein, setProtein] = useState('')
  const [carbs, setCarbs] = useState('')
  const [fat, setFat] = useState('')
  const [sugars, setSugars] = useState('')
  const [fibre, setFibre] = useState('')
  const [isSnack, setIsSnack] = useState('')
  const [isBreakfast, setIsBreakfast] = useState('')
  const [dietaryProfile, setDietaryProfile] = useState('0')
  const [open, setOpen] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    const token = localStorage.getItem('token')
    axios.post(process.env.REACT_APP_API_URL + '/meals', {
      name: mealName,
      description,
      calories,
      ingredients,
      instructions,
      protein,
      carbs,
      fats: fat,
      sugars,
      fibre,
      is_snack: isSnack,
      is_breakfast: isBreakfast,
      dietary_profile: dietaryProfile
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      console.log(response)
      setMealName('')
      setDescription('')
      setIngredients('')
      setInstructions('')
      setCalories('')
      setProtein('')
      setCarbs('')
      setFat('')
      setSugars('')
      setFibre('')
      setDietaryProfile(0)
      setIsSnack('')
      setIsBreakfast('')
    }).catch(error => {
      console.error('There was an error!', error)
    })
  }

  const generateMeal = (event) => {
    event.preventDefault()
    setOpen(true)
    const token = localStorage.getItem('token')
    axios.post(process.env.REACT_APP_API_URL + '/generator', {
      fields: 'mealName, description, calories, ingredients, protein, carbs, fat, sugars, fibre, is_snack(true or false),dietary_profile, is_breakfast(true or false), instructions',
      hint: 'Please include weights of ingredients: ' + mealName + '; ' + description + '. When writing the description can we include the weights in there too? e.g. 120g of DoodA with 125g of FoodB with 50g FoodC on the side'
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      console.log(response.data)
      setMealName(response.data.mealName)
      setDescription(response.data.description)
      setCalories(parseFloat(response.data.calories))
      setProtein(parseFloat(response.data.protein))
      setIngredients(response.data.ingredients)
      setInstructions(response.data.instructions)
      setCarbs(parseFloat(response.data.carbs))
      setFat(parseFloat(response.data.fat))
      setSugars(parseFloat(response.data.sugars))
      setFibre(parseFloat(response.data.fibre))
      setDietaryProfile(0)
      setIsSnack(response.data.is_snack)
      setIsBreakfast(response.data.is_breakfast)
      setOpen(false)
    }).catch(error => {
      console.error('There was an error!', error)
      setOpen(false)
    })
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mx: 'auto', maxWidth: 400, mt: 2 }}>
      <Grid container direction="column" spacing={2}>
        <Grid item><TextField label="Meal Name" value={mealName} onChange={(e) => setMealName(e.target.value)} required /></Grid>
        <Grid item><TextField label="Description" value={description} onChange={(e) => setDescription(e.target.value)} required /></Grid>
        <Grid item><TextField label="Calories" value={calories} onChange={(e) => setCalories(e.target.value)} required /></Grid>
        <Grid item><TextField label="Protein" value={protein} onChange={(e) => setProtein(e.target.value)} required /></Grid>
        <Grid item><TextField label="Carbs" value={carbs} onChange={(e) => setCarbs(e.target.value)} required /></Grid>
        <Grid item><TextField label="Fat" value={fat} onChange={(e) => setFat(e.target.value)} required /></Grid>
        <Grid item><TextField label="Sugars" value={sugars} onChange={(e) => setSugars(e.target.value)} required /></Grid>
        <Grid item><TextField label="Fibre" value={fibre} onChange={(e) => setFibre(e.target.value)} required /></Grid>
        <Grid item><TextField label="Dietary Profile" value={dietaryProfile} onChange={(e) => setDietaryProfile(e.target.value)} required /></Grid>
        <Grid item>Snack?<Checkbox label="Snack?" value={isSnack} onChange={(e) => setIsSnack(e.target.checked)} /></Grid>
        <Grid item>Breakfast?<Checkbox label="Breakfast?" value={isBreakfast} onChange={(e) => setIsBreakfast(e.target.checked)} /></Grid>
        <Grid item><Button onClick={generateMeal} variant="contained" color="primary">Generate Meal</Button></Grid>
        <Grid item><Button type="submit" variant="contained" color="primary">Add Meal</Button></Grid>
      </Grid>
      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
      >
        <DialogContent>
        Generating Random Meal ...
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default AddMeal
