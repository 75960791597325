// src/MuscleList.js
export const MUSCLE_LIST = {
  1: 'Chest',
  2: 'Upper Back',
  4: 'Lower Back',
  8: 'Shoulders',
  16: 'Biceps',
  32: 'Triceps',
  64: 'Forearms',
  128: 'Quadriceps',
  256: 'Hamstrings',
  512: 'Calves',
  1024: 'Glutes',
  2048: 'Core',
  4096: 'Hip Flexors',
  8192: 'Neck',
  16384: 'Legs',
  32768: 'Arms',
  65536: 'Back',
  131072: 'Cardio'
}

export const getMuscleList = (muscleBitMask) => {
  const muscles = []
  for (const bit in MUSCLE_LIST) {
    if ((muscleBitMask & bit) !== 0) {
      muscles.push(MUSCLE_LIST[bit])
    }
  }
  return muscles.join(', ')
}
