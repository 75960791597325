import { Grid, Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { decodeToken } from 'react-jwt'

const Progress = () => {
  const [progress, setProgress] = useState([])
  const [decodedToken, setDecodedToken] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [activeProgress, setActiveProgress] = useState(0)
  const [reload, setReload] = useState(true)

  const openDialog = () => {
    setOpenDeleteDialog(true)
  }

  const closeDialog = () => {
    setOpenDeleteDialog(false)
  }

  useEffect(() => {
    if (reload === true) {
      const token = localStorage.getItem('token')
      setDecodedToken(decodeToken(token))

      axios.get(process.env.REACT_APP_API_URL + '/clients/progress', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          const data = response.data
          setProgress(data)
          setReload(false)
        })
        .catch(error => {
          console.error('There was an error!', error)
        })
    }
  }, [reload])

  const deleteProgress = () => {
    const token = localStorage.getItem('token')
    const url = `${process.env.REACT_APP_API_URL}/clients/progress/${activeProgress}`
    axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        // console.log('Workout updated successfully:', response.data)
        setReload(true)
      })
      .catch(error => {
        console.error('There was an error deleting the workout!', error)
      })
      .finally(e => {
        closeDialog()
      })
  }

  return (
    <Grid>
       <Dialog
          open={openDeleteDialog}
          onClose={closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{'Confirm Deletion'}</DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this progress entry?
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={closeDialog} color="primary">
                  Cancel
              </Button>
              <Button onClick={deleteProgress} color="primary" autoFocus>
                  Yes, Delete
              </Button>
          </DialogActions>
        </Dialog>
      {progress.map((p) => (
        <Paper key={p.id} sx={{ padding: '10px', marginBottom: '10px', minHeight: '80px' }}>
          <Grid container direction="column" spacing={2} sx={{ marginBottom: '20px' }}>

            <Grid item sx={{ paddingTop: 0 }}>
              {p.date}
              {decodedToken?.role === 'admin' && <IconButton onClick={() => { setActiveProgress(p.id); openDialog(true) }} sx={{ position: 'relative', top: 0, right: 0 }} size="small">
                <DeleteIcon />
              </IconButton>}
            </Grid>
            <Grid item sx={{ paddingTop: 0 }}>{p.message}</Grid>
          </Grid>
        </Paper>
      ))}
    </Grid>
  )
}

export default Progress
