import { Box, Button, TextField, Grid } from '@mui/material'
import React, { useState } from 'react'
import axios from 'axios'

const AddCoach = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    const token = localStorage.getItem('token')
    axios.post(process.env.REACT_APP_API_URL + '/coaches', {
      name,
      email,
      business_name: businessName,
      password
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      console.log(response)
      setName('')
      setEmail('')
      setBusinessName('')
      setPassword('')
    }).catch(error => {
      console.error('There was an error!', error)
    })
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mx: 'auto', maxWidth: 400, mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Business Name"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Add Coach
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddCoach
