import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import ListClients from '../../components/ListClients'

const CoachListClients = () => {
  useEffect(() => {
  }, [])

  return (
    <Box sx={{ textAlign: 'left', padding: '20px', mx: 'auto', whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
      <Typography sx={{ padding: '0px' }}variant="h4">
         Your Clients
      </Typography>
      <ListClients />
    </Box>
  )
}

export default CoachListClients
