import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import ListWorkouts from '../../components/ListWorkouts'
import PropTypes from 'prop-types'

const CoachListWorkouts = ({ coachId }) => {
  useEffect(() => {
  }, [])

  return (
    <div>
      <Box sx={{ textAlign: 'left', padding: '20px', mx: 'auto', whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
        <Typography sx={{ padding: '0px' }}variant="h4">
          Your Workouts
        </Typography>
        <ListWorkouts coachId={coachId} />
      </Box>
      <Box sx={{ textAlign: 'left', padding: '20px', mx: 'auto', whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
      <Typography sx={{ padding: '0px' }}variant="h4">
        bdvoted Workouts
      </Typography>
      <ListWorkouts coachId={0} />
    </Box>
  </div>
  )
}

CoachListWorkouts.propTypes = {
  coachId: PropTypes.number
}

export default CoachListWorkouts
