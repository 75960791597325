import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { decodeToken } from 'react-jwt'

const Admin = () => {
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const decodedToken = decodeToken(token)

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'prod') {
      return
    }

    if (!decodedToken || decodedToken.role !== 'admin') {
      if (decodedToken.role === 'coach') {
        navigate('/coaches')
      } else {
        navigate('/clients')
      }
    }
  })

  return (
    <div>
      <main>
      </main>
    </div>
  )
}

export default Admin
