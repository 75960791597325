import { Box, Button, TextField, Grid, FormControl, InputLabel, Select, MenuItem, Dialog, DialogContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios'

const AddClient = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [height, setHeight] = useState('')
  const [weight, setWeight] = useState('')
  const [waist, setWaist] = useState('')
  const [hip, setHip] = useState('')
  const [neck, setNeck] = useState('')
  const [dietaryProfile, setDietaryProfile] = useState('')
  const [coachId, setCoachId] = useState('-1')

  const [coaches, setCoaches] = useState([])

  const [open, setOpen] = useState(false)
  const token = localStorage.getItem('token')
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + '/coaches', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setCoaches(response.data)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()

    axios.post(process.env.REACT_APP_API_URL + '/clients', {
      name,
      email,
      height,
      weight,
      waist,
      hip,
      neck,
      dietary_profile: dietaryProfile,
      coach_id: coachId
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      console.log(response)
      setName('')
      setEmail('')
      setHeight('')
      setWeight('')
      setWaist('')
      setHip('')
      setNeck('')
      setDietaryProfile('')
      setCoachId('')
    }).catch(error => {
      console.error('There was an error!', error)
    })
  }
  const generateClient = (event) => {
    event.preventDefault()
    setOpen(true)
    const token = localStorage.getItem('token')
    axios.post(process.env.REACT_APP_API_URL + '/generator', {
      fields: 'name, email, height, weight, waist, hip, neck',
      hint: 'this is a person, please be more random than just John Doe!'
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      const min = 0 // the minimum number you want
      const max = 3 // the maximum number you want
      const random = Math.floor(Math.random() * (max - min + 1)) + min

      const zmin = 0 // the minimum number you want
      const zmax = 3000 // the maximum number you want
      const zrandom = Math.floor(Math.random() * (zmax - zmin + 1)) + min
      console.log(response.data)
      setName(response.data.name)
      setEmail(response.data.email)
      setHeight(parseFloat(response.data.height))
      setWeight(parseFloat(response.data.weight))
      setWaist(parseFloat(response.data.waist))
      setHip(parseFloat(response.data.hip))
      setNeck(parseFloat(response.data.neck))
      setDietaryProfile(zrandom)
      setCoachId(random)
      setOpen(false)
    }).catch(error => {
      console.error('There was an error!', error)
      setOpen(false)
    })
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mx: 'auto', maxWidth: 400, mt: 2 }}>
      <Grid container direction="column" spacing={2}>
        <Grid item><TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} required /></Grid>
        <Grid item><TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} required /></Grid>
        <Grid item><TextField label="Height" value={height} onChange={(e) => setHeight(e.target.value)} required /></Grid>
        <Grid item><TextField label="Weight" value={weight} onChange={(e) => setWeight(e.target.value)} required /></Grid>
        <Grid item><TextField label="Waist" value={waist} onChange={(e) => setWaist(e.target.value)} required /></Grid>
        <Grid item><TextField label="Hip" value={hip} onChange={(e) => setHip(e.target.value)} required /></Grid>
        <Grid item><TextField label="Neck" value={neck} onChange={(e) => setNeck(e.target.value)} required /></Grid>
        <Grid item><TextField label="Dietary Profile" value={dietaryProfile} onChange={(e) => setDietaryProfile(e.target.value)} required /></Grid>
        <Grid item><FormControl required>
            <InputLabel>Coach ID</InputLabel>
            <Select
              value={coachId}
              onChange={(e) => setCoachId(e.target.value)}
            >
              <MenuItem value="-1">
                <em>No Coach</em>
              </MenuItem>
              {coaches.map(coach => (
                <MenuItem key={coach.id} value={coach.id}>{coach.name}</MenuItem>
              ))}
            </Select>
          </FormControl></Grid>
          <Grid item><Button onClick={generateClient} variant="contained" color="primary">Generate Client</Button></Grid>
        <Grid item><Button type="submit" variant="contained" color="primary">Add Client</Button></Grid>
      </Grid>
      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
      >
        <DialogContent>
          Generating Random Client ...
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default AddClient
