import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import ViewWorkout from '../../components/ViewWorkout'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

const CoachViewWorkout = ({ coachId }) => {
  const { id } = useParams()
  useEffect(() => {
  }, [])

  return (
    <Box sx={{ textAlign: 'left', padding: '20px', mx: 'auto', whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
      <ViewWorkout workoutId={parseInt(id)} coachId={coachId} />
    </Box>
  )
}

CoachViewWorkout.propTypes = {
  coachId: PropTypes.number
}

export default CoachViewWorkout
