import React, { useEffect, useState } from 'react'
import { useRoutes } from 'react-router-dom'
import ClientPortal from './clients/Portal'
import ClientMealPlans from './clients/MealPlans'
import ClientWorkouts from './clients/Workouts'
import ClientMeasurements from './clients/Measurements'
import ClientProgress from './clients/Progress'
import axios from 'axios'
import PropTypes from 'prop-types'

const Clients = ({ userId, username }) => {
  const [clientData, setClientData] = useState({})
  const [workoutId, setWorkoutId] = useState(false)
  const [workoutDay, setWorkoutDay] = useState(1)
  const [workoutData, setWorkoutData] = useState({})

  const routes = useRoutes([
    { path: '/', element: <ClientPortal client={clientData} workout={workoutData} userId={userId} username={username} /> },
    { path: '/mealplans', element: <ClientMealPlans /> },
    { path: '/workouts', element: <ClientWorkouts updateWorkoutDay={setWorkoutDay} client={clientData} workout={workoutData} userId={userId} username={username} currentWorkoutDay={workoutDay} /> },
    { path: '/measurements', element: <ClientMeasurements /> },
    { path: '/progress', element: <ClientProgress /> }

  ])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (workoutId && workoutDay) {
      const url = process.env.REACT_APP_API_URL + '/workout/' + workoutId + '?workout_day=' + workoutDay
      axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          const workout = response.data
          setWorkoutData(workout)
        })
        .catch(error => {
          console.error('There was an error!', error)
        })
    }
  }, [workoutId, workoutDay])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (userId) {
      axios.get(process.env.REACT_APP_API_URL + '/clients/' + userId, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          const client = response.data
          setClientData(client)
          if (client.workout_id && client.workout_id > 0) {
            setWorkoutId(client.workout_id)
            setWorkoutDay(client.workout_day)
          }
        })
        .catch(error => {
          console.error('There was an error!', error)
        })
    }
  }, [userId, username])

  return (
    <div>
      <main>
      {routes}
      </main>
    </div>
  )
}

Clients.propTypes = {
  userId: PropTypes.number,
  username: PropTypes.string
}

export default Clients
