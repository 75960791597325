import { Box, Button, TextField, Grid, FormControl, InputLabel, Select, MenuItem, Dialog, DialogContent } from '@mui/material'
import { useParams, Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ClientStats from './ClientStats'
import AddMealPlan from './AddMealPlan'
import AnalyzeClient from './AnalyzeClient'

const EditClient = () => {
  const [clientId, setClientId] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [height, setHeight] = useState('')
  const [weight, setWeight] = useState('')
  const [waist, setWaist] = useState('')
  const [hip, setHip] = useState('')
  const [neck, setNeck] = useState('')
  const [squat, setSquat] = useState('')
  const [bench, setBench] = useState('')
  const [deadlift, setDeadlift] = useState('')
  const [overheadPress, setOverheadPress] = useState('')
  const [barbellRow, setBarbellRow] = useState('')
  const [gender, setGender] = useState('')
  const [password, setPassword] = useState('')
  const [dietaryProfile, setDietaryProfile] = useState('')
  const [goal, setGoal] = useState('maintain')
  const [coachId, setCoachId] = useState('-1')
  const [exerciseCalories, setExerciseCalories] = useState('0')
  const [calorieGoal, setCalorieGoal] = useState('0')
  const [proteinGoal, setProteinGoal] = useState('0')

  const { id } = useParams()

  const [coaches, setCoaches] = useState([])

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(true)
    const token = localStorage.getItem('token')
    axios.get(process.env.REACT_APP_API_URL + '/coaches', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setCoaches(response.data)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })

    axios.get(process.env.REACT_APP_API_URL + '/clients/' + id, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        const client = response.data // extract the first client from the response
        setClientId(client.id)
        setName(client.name)
        setEmail(client.email)
        setHeight(parseFloat(client.height))
        setWeight(parseFloat(client.weight))
        setWaist(parseFloat(client.waist))
        setHip(parseFloat(client.hip))
        setNeck(parseFloat(client.neck))
        setSquat(parseFloat(client.squat))
        setBench(parseFloat(client.bench))
        setDeadlift(parseFloat(client.deadlift))
        setOverheadPress(parseFloat(client.overhead_press))
        setBarbellRow(parseFloat(client.barbell_row))
        setDietaryProfile(client.dietary_profile)
        setGender(client.gender)
        setCoachId(client.coach_id)
        setGoal(client.goal)
        setExerciseCalories(client.exercise_calories)
        setOpen(false)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }, [id])

  const handleSubmit = (event) => {
    event.preventDefault()
    const token = localStorage.getItem('token')
    setOpen(true)
    axios.put(process.env.REACT_APP_API_URL + '/clients/' + id, {
      name,
      email,
      height,
      weight,
      waist,
      hip,
      neck,
      squat,
      bench,
      deadlift,
      overhead_press: overheadPress,
      barbell_row: barbellRow,
      dietary_profile: dietaryProfile,
      coach_id: coachId,
      goal,
      exercise_calories: exerciseCalories,
      password
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      console.log(response)
      setOpen(false)
    }).catch(error => {
      console.error('There was an error!', error)
      setOpen(false)
    })
  }

  return (
    <Grid container spacing={2} sx={{ border: '0px solid red;' }}>
        <Grid item xs={2}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mx: 'auto', maxWidth: 400, mt: 2 }}>
            <Grid container direction="column" spacing={2}>
                <Grid item><TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} required /></Grid>
                <Grid item><TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} required /></Grid>
                <Grid item><TextField label="Password" value={password} onChange={(e) => setPassword(e.target.value)} /></Grid>
                <Grid item><FormControl required>
                    <InputLabel>Goal</InputLabel>
                    <Select
                    value={goal}
                    onChange={(e) => setGoal(e.target.value)}
                    >
                        <MenuItem key="lose" value="lose">Lose</MenuItem>
                        <MenuItem key="maintain" value="maintain">Maintain</MenuItem>
                        <MenuItem key="gain" value="gain">Gain</MenuItem>
                    </Select>
                </FormControl></Grid>
                <Grid item><TextField label="Exercise Calories" value={exerciseCalories} onChange={(e) => setExerciseCalories(e.target.value)} required /></Grid>
                <Grid item><TextField label="Height" value={height} onChange={(e) => setHeight(e.target.value)} required /></Grid>
                <Grid item><TextField label="Weight" value={weight} onChange={(e) => setWeight(e.target.value)} required /></Grid>
                <Grid item><TextField label="Waist" value={waist} onChange={(e) => setWaist(e.target.value)} required /></Grid>
                <Grid item><TextField label="Hip" value={hip} onChange={(e) => setHip(e.target.value)} required /></Grid>
                <Grid item><TextField label="Neck" value={neck} onChange={(e) => setNeck(e.target.value)} required /></Grid>
                <Grid item><TextField label="1RM Squat" value={squat} onChange={(e) => setSquat(e.target.value)} /></Grid>
                <Grid item><TextField label="1RM Bench" value={bench} onChange={(e) => setBench(e.target.value)} /></Grid>
                <Grid item><TextField label="1RM Dead" value={deadlift} onChange={(e) => setDeadlift(e.target.value)} /></Grid>
                <Grid item><TextField label="1RM OHP" value={overheadPress} onChange={(e) => setOverheadPress(e.target.value)} /></Grid>
                <Grid item><TextField label="1RM BB Row" value={barbellRow} onChange={(e) => setBarbellRow(e.target.value)} /></Grid>
                <Grid item><TextField label="Calorie Goal" value={calorieGoal} readonly /></Grid>
                <Grid item><TextField label="Protein Goal" value={proteinGoal} readonly /></Grid>

                <Grid item><TextField label="Dietary Profile" value={dietaryProfile} onChange={(e) => setDietaryProfile(e.target.value)} required /></Grid>
                <Grid item><FormControl required>
                    <InputLabel>Coach ID</InputLabel>
                    <Select
                    value={coachId}
                    onChange={(e) => setCoachId(e.target.value)}
                    >
                    <MenuItem value="-1">
                        <em>No Coach</em>
                    </MenuItem>
                    {coaches.map(coach => (
                        <MenuItem key={coach.id} value={coach.id}>{coach.name}</MenuItem>
                    ))}
                    </Select>
                </FormControl></Grid>
                <Grid item><Button type="submit" variant="contained" color="primary">Update Client</Button></Grid>
            </Grid>
            <Dialog
                open={open}
                keepMounted
                onClose={() => setOpen(false)}
            >
                <DialogContent>
                Saving/Loading...
                </DialogContent>
            </Dialog>
            </Box>
        </Grid>
        <Grid item xs={10}>
          <div><Link to={`/coaches/exercises/list?client_id=${clientId}`}>Full Exercise List</Link></div>
          <div><Link to={`https://b6l75gwemft.typeform.com/to/UEkMYOsj#client_name=${name}&client_email=${email}`}>Weekly Checkin Form</Link></div>
          <div><Link to={`https://b6l75gwemft.typeform.com/to/gc6ZCGYM#client_name=${name}&client_email=${email}`}>1RM Form</Link></div>
          <ClientStats exerciseCalories={exerciseCalories} goal={goal} height={height} weight={weight} waist={waist} hip={hip} neck={neck} gender={gender} updateCalorieGoal={setCalorieGoal} updateProteinGoal={setProteinGoal} />
          <AddMealPlan clientId={clientId} name={name} goal={goal} exerciseCalories={exerciseCalories} proteinGoal={proteinGoal} calorieGoal={calorieGoal} dietaryProfile={dietaryProfile} updateDietaryProfile={setDietaryProfile}/>
          <AnalyzeClient clientId={clientId} />
        </Grid>
    </Grid>
  )
}

export default EditClient
