import React, { useState, createContext, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import axios from 'axios'
import { decodeToken } from 'react-jwt'

export const LoginContext = createContext()

export const LoginProvider = ({ children }) => {
  const navigate = useNavigate()
  const [username, setUsername] = useState(null)
  const [businessName, setBusinessName] = useState(null)
  const [logoUrl, setLogoUrl] = useState(null)

  const handleLogin = (decodedToken) => {
    setUsername(decodedToken.name)
    setBusinessName(decodedToken.businessName)
    setLogoUrl(decodedToken.logo_url)
  }

  const handleLogout = () => {
    setUsername(null)
    setBusinessName(null)
    setLogoUrl(null)
    localStorage.removeItem('token')
    navigate('/')
  }

  const refreshToken = async () => {
    const token = localStorage.getItem('token')
    if (token) {
      axios.post(process.env.REACT_APP_API_URL + '/token-refresh', {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          // If the response is successful, update the token in local storage
          if (response.data && response.data.token) {
            const decodedToken = decodeToken(response.data.token)
            setUsername(decodedToken.name)
            localStorage.setItem('token', response.data.token)
          }
        })
        .catch(error => {
          // If there's an error, remove the token from local storage
          console.error('There was an error refreshing the token', error)
          handleLogout()
        })
    }
  }

  useEffect(() => {
    refreshToken()
    const intervalId = setInterval(refreshToken, 15 * 60 * 1000)
    return () => clearInterval(intervalId)
  }, [username])

  return (
    <LoginContext.Provider
      value={{
        username,
        businessName,
        logoUrl,
        handleLogin,
        handleLogout
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}

LoginProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useLogin = () => {
  return useContext(LoginContext)
}
