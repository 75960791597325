import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, CircularProgress } from '@mui/material'

const ListMealPlans = () => {
  const [mealPlans, setMealPlans] = useState([])
  const [loading, setLoading] = useState(true)
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' })

  const onSort = (key) => {
    let direction = 'ascending'
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    axios.get(process.env.REACT_APP_API_URL + '/mealplans', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setMealPlans(response.data)
        setLoading(false)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }, [])

  const sortedMealPlans = [...mealPlans]

  if (sortConfig !== null) {
    sortedMealPlans.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1
      }
      return 0
    })
  }

  if (loading) {
    return <CircularProgress />
  }

  return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sortDirection={sortConfig.direction}>
                <TableSortLabel active={sortConfig.key === 'name'} direction={sortConfig.direction} onClick={() => onSort('name')}>Name</TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sortConfig.direction}>
                <TableSortLabel active={sortConfig.key === 'description'} direction={sortConfig.direction} onClick={() => onSort('description')}>Description</TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedMealPlans.map((mealPlan) => (
              <TableRow key={mealPlan.id}>
                <TableCell>{mealPlan.name}</TableCell>
                <TableCell>{mealPlan.description}</TableCell>
                {/* Add more fields as necessary */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  )
}

export default ListMealPlans
