import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.scss'
import Admin from './Admin'
import AdminCoaches from './admin/Coaches'
import AdminClients from './admin/Clients'
import Coaches from './Coaches'
import Clients from './Clients'
import ClientSignup from './clients/Signup'
import Meals from './admin/Meals'
import Login from './Login'
import TopBar from './components/Nav'
import FixedFooter from './components/FixedFooter'
import ProtectedRoute from './utils/protectedRoute'
import { decodeToken } from 'react-jwt'
import axios from 'axios'
import { LoginProvider } from './components/LoginContext'

const App = () => {
  const [userId, setUserId] = useState(null)
  const [username, setUsername] = useState(null)
  const [businessName, setBusinessName] = useState(null)
  const [logoUrl, setLogoUrl] = useState(null)
  const [token, setToken] = useState(null)

  const updateUserToken = (token) => {
    let updatedToken = token
    updatedToken = localStorage.getItem('token')
    setToken(updatedToken)
  }
  useEffect(() => {
    const newToken = localStorage.getItem('token')
    if (newToken) {
      const decodedToken = decodeToken(newToken)
      setUserId(decodedToken.id)
      setUsername(decodedToken.name)
      setBusinessName(decodedToken.businessName)
      setLogoUrl(decodedToken.logo_url)
      axios.post(process.env.REACT_APP_API_URL + '/token-refresh', {}, {
        headers: {
          Authorization: `Bearer ${newToken}`
        }
      })
        .then(response => {
          // If the response is successful, update the token in local storage
          if (response.data && response.data.token) {
            localStorage.setItem('token', response.data.token)
          }
        })
        .catch(error => {
          // If there's an error, remove the token from local storage
          console.error('There was an error refreshing the token', error)
          localStorage.removeItem('token')
        })
    }
  }, [token])

  return (
  <Router>
    <LoginProvider>
      <div className="fixed-topbar">
        <TopBar updateUserToken={updateUserToken} />
      </div>
      <div className="App">
        <Routes styles={{ position: 'relative' }} >
          <Route path="/login" element={<Login type="client" onLogin={updateUserToken} />} />
          <Route path="/coaches/login" element={<Login type="coach" onLogin={updateUserToken} />} />
          <Route path="/admin" element={<ProtectedRoute><Admin /></ProtectedRoute>}></Route>
          <Route path="/admin/coaches/*" element={<ProtectedRoute><AdminCoaches /></ProtectedRoute>} />
          <Route path="/admin/clients/*" element={<ProtectedRoute><AdminClients /></ProtectedRoute>} />
          <Route path="/admin/meals/*" element={<ProtectedRoute><Meals /></ProtectedRoute>} />
          <Route path="/clients/signup" element={<ClientSignup />} />
          <Route path="/clients/*" element={<ProtectedRoute><Clients userId={userId} username={username} /></ProtectedRoute>} />
          <Route path="/coaches/*" element={<ProtectedRoute><Coaches userId={userId} username={username} businessName={businessName} logoUrl={logoUrl} /></ProtectedRoute>} />
          <Route path="/" element={
            <header className="header">
              <h1>Welcome to bdvoted!</h1>
              <p>Coming soon!</p>
            </header>
          } />
        </Routes>
      </div>
      <div className="fixed-footer">
        <FixedFooter />
      </div>
    </LoginProvider>
  </Router>
  )
}

export default App
