import React, { useEffect, useState, useMemo } from 'react'
import { Button, Typography } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import './Nav.scss'
import { useLogin } from './LoginContext'
import PropTypes from 'prop-types'
import MenuIcon from '@mui/icons-material/Menu'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

const LoginLogoutButton = ({
  username,
  handleLogout,
  handleLogin,
  isBurger,
  renderButton = true,
  renderWelcomeMessage = true
}) => {
  const containerClass = `loginOutContainer ${isBurger ? 'logInOutContainerBurger' : 'logInOutContainerNav'}`
  const isLoggedIn = Boolean(username)

  return (
    <div className={containerClass}>
      {isLoggedIn && renderWelcomeMessage && (
        <Typography variant="subtitle1" className='welcomeMessage'>
          Welcome, {username}!
        </Typography>
      )}
      {renderButton && (
        <Button
          variant="contained"
          onClick={isLoggedIn ? handleLogout : handleLogin}
        >
          {isLoggedIn ? 'Logout' : 'Login'}
        </Button>
      )}
    </div>
  )
}

LoginLogoutButton.propTypes = {
  username: PropTypes.string,
  handleLogout: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  isBurger: PropTypes.bool,
  renderButton: PropTypes.bool,
  renderWelcomeMessage: PropTypes.bool
}

const BurgerMenu = ({ username, handleLogout, handleLogin, toggleBurger }) => {
  const initialLinks = useMemo(() => [
    { name: 'Home', pathname: '/admin' }
  ], [])

  const clientLinks = useMemo(() => [
    { name: 'Home', pathname: '/clients' },
    { name: 'MealPlans', pathname: '/clients/mealplans' },
    { name: 'Workouts', pathname: '/clients/workouts' },
    { name: 'Measurements', pathname: '/clients/measurements' },
    { name: 'Progress', pathname: '/clients/progress' }
  ], [])

  const adminLinks = useMemo(() => [
    { name: 'Home', pathname: '/admin' },
    { name: 'Coaches', pathname: '/admin/coaches', indentedPath: [{ name: 'List', pathname: '/admin/coaches/list' }, { name: 'Add', pathname: '/admin/coaches/add' }], indentActive: false },
    { name: 'Clients', pathname: '/admin/clients' },
    { name: 'Meals', pathname: '/admin/meals' },
    { name: 'MealPlans', pathname: '/admin/meals/plans' }
  ], [])

  const coachLinks = useMemo(() => [
    { name: 'Home', pathname: '/coaches' },
    { name: 'Clients', pathname: '/coaches/clients/list', indentedPath: [{ name: 'List', pathname: '/coaches/clients/list' }, { name: 'Add', pathname: '/coaches/clients/add' }], indentActive: false },
    { name: 'Meals', pathname: '/coaches/meals/list', indentedPath: [{ name: 'List', pathname: '/coaches/meals/list' }, { name: 'Add', pathname: '/coaches/meals/add' }], indentActive: false },
    { name: 'Exercises', pathname: '/coaches/exercises/list', indentedPath: [{ name: 'List', pathname: '/coaches/exercises/list' }, { name: 'Add', pathname: '/coaches/exercises/add' }], indentActive: false },
    { name: 'Workouts', pathname: '/coaches/workouts/list' }
  ], [])

  const [currentPath, setCurrentPath] = useState('')
  const location = useLocation()
  useEffect(() => {
    if (currentPath.startsWith('/admin')) {
      setLinks(adminLinks)
    }
    if (currentPath.startsWith('/clients')) {
      setLinks(clientLinks)
    }
    if (currentPath.startsWith('/coaches')) {
      setLinks(coachLinks)
    }
  }, [location, currentPath])

  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [location])

  const [links, setLinks] = useState(initialLinks)
  const navigate = useNavigate()

  const toggleIndentActive = (index) => {
    setLinks(links.map((link, idx) => ({
      ...link,
      indentActive: idx === index ? !link.indentActive : false
    })))
  }

  const handleLinkClick = (link, index) => () => {
    if (!link.indentedPath || link.indentedPath.length === 0) {
      navigate(link.pathname)
      toggleBurger()
    } else {
      toggleIndentActive(index)
    }
  }

  const handleIndentedLinkClick = (pathname) => () => {
    navigate(pathname)
    toggleBurger()
  }

  const renderLinks = (links) => links.map((link, index) => (
    <React.Fragment key={link.name}>
      <button onClick={handleLinkClick(link, index)} className={`${link.indentedPath ? 'iconButton' : ''} burgerLink`}>
        {link.name}
        {link.indentedPath && (link.indentActive ? <RemoveIcon /> : <AddIcon />)}
      </button>
      <div className='indentedLinksContainer'>
        {link.indentActive && link.indentedPath && link.indentedPath.map(subLink => (
          <div key={subLink.name} className="indentedLink">
            <button onClick={handleIndentedLinkClick(subLink.pathname)} className='burgerLink'>
              {subLink.name}
            </button>
          </div>
        ))}
      </div>
    </React.Fragment>
  ))

  return (
    <div className='burgerMenu'>
      <div className='burgerInnerWrapper'>
       <div /> {/* this is here so for hacky reasons - without the links aren't centered */}
        <div className='burgerLinks'>
          {renderLinks(links)}
        </div>
        <div className='burgerLoginOutWrapper'>
          <LoginLogoutButton
            username={username}
            handleLogout={handleLogout}
            handleLogin={handleLogin}
            isBurger={true}
            renderButton={true}
            renderWelcomeMessage={false}
          />
        </div>
      </div>
    </div>
  )
}

BurgerMenu.propTypes = {
  username: PropTypes.string,
  handleLogout: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  toggleBurger: PropTypes.func
}

const Nav = ({ updateUserToken }) => {
  const navigate = useNavigate()
  const { username, handleLogout } = useLogin()
  const [burgeractive, setBurgerActive] = useState(false)

  const isProduction = process.env.REACT_APP_ENV === 'prod'
  const title = isProduction ? 'bdvoted' : `bdvoted - ${process.env.REACT_APP_ENV}`

  useEffect(() => {
    updateUserToken(username)
  }, [username, updateUserToken])

  const handleLogin = () => {
    navigate('/login')
    toggleBurger()
  }

  const toggleBurger = () => {
    setBurgerActive(!burgeractive)
  }

  return (
    <div className='navWrapper'>
      <div className='navBar'>
        <div className='navLeft'>
          <img src="/images/logo.png" alt="Logo" id='logo' />
          <Typography variant="h6">
            {title}
          </Typography>
        </div>
        <div className='navRight'>
          <LoginLogoutButton
            username={username}
            handleLogout={handleLogout}
            handleLogin={handleLogin}
            isBurger={false}
            renderButton={false}
            renderWelcomeMessage={true}
          />
          <button className='burgerWrapper' onClick={toggleBurger}>
            <MenuIcon className='menuIcon' />
          </button>
        </div>
      </div>
        {burgeractive && (
          <BurgerMenu
            username={username}
            handleLogout={handleLogout}
            handleLogin={handleLogin}
            toggleBurger={toggleBurger}
          />
        )}
      </div>
  )
}

Nav.propTypes = {
  updateUserToken: PropTypes.func.isRequired
}

export default Nav
