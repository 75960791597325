import { Box, Button, Checkbox, FormControlLabel, Grid, Dialog, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { DIETARY_PROFILES } from '../DietaryProfiles'
import PropTypes from 'prop-types'

const AddMeal = (props) => {
  const [mealPlans, setMealPlans] = useState({}) // Initialize state to hold meal plans
  const [selectedProfiles, setSelectedProfiles] = useState({})
  // const [combinedProfiles, setCombinedProfiles] = useState(0)

  const [open, setOpen] = useState(false)

  const [meals, setMeals] = useState([]) // Add a new state variable to hold all meals

  useEffect(() => {
    // Load meals when the component mounts
    const token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${token}`
    }

    // Create a promise for each request
    const mealsPromise = axios.get(process.env.REACT_APP_API_URL + '/meals?coach_id=0', { headers })
    const coachMealsPromise = axios.get(process.env.REACT_APP_API_URL + '/meals', { headers })

    // Use Promise.all to wait for both requests to finish
    Promise.all([mealsPromise, coachMealsPromise])
      .then((responses) => {
        // Both requests are done
        // responses[0] contains the result from the first request
        // responses[1] contains the result from the second request

        // Merge the data from both responses
        const allMeals = [...responses[0].data, ...responses[1].data]

        // Update the state
        setMeals(allMeals)
      })
      .catch((error) => console.error('There was an error!', error))
  }, []) // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    if (props.dietaryProfile) {
      const selectedProfiles = {}
      // Iterate over the dietary profiles
      for (const value in DIETARY_PROFILES) {
        // Check if the bitwise AND operation between the props.dietaryProfile
        // and the current profile value is truthy
        if ((props.dietaryProfile & value) !== 0) {
          // If it is, mark the profile as selected
          selectedProfiles[value] = true
        } else {
          // If it isn't, mark the profile as not selected
          selectedProfiles[value] = false
        }
      }
      // setDietaryProfile(props.dietaryProfile);
      setSelectedProfiles(selectedProfiles)
    }
  }, [props.dietaryProfile, props.exerciseCalories])

  useEffect(() => {
    const combinedValue = Object.entries(selectedProfiles)
      .filter(([, isSelected]) => isSelected) // Filter to only the selected profiles
      .reduce((total, [value]) => total + Number(value), 0)
    console.log('Combined Profiles: ' + combinedValue)
    // setCombinedProfiles(combinedValue)
    props.updateDietaryProfile(combinedValue)
  }, [selectedProfiles])

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log(mealPlans)

    const token = localStorage.getItem('token')
    axios.post(process.env.REACT_APP_API_URL + '/mealplans', {
      coach_id: 0,
      name: props.name + '\'s Meal Plan',
      mealplans: mealPlans,
      client_id: props.clientId
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      console.log(response)
      // setMealPlanName("");
    }).catch(error => {
      console.error('There was an error!', error)
    })
  }

  // const generateDayMealPlan = (event, day) => {
  //   event.preventDefault()
  //   setOpen(true)
  //   const token = localStorage.getItem('token')
  //   axios.post(process.env.REACT_APP_API_URL + '/generator/mealplans', {
  //     hint: `just ${day} - aim for ` + props.calorieGoal + ' calories. Where possible, also aim for ' + props.proteinGoal + 'g of protein.',
  //     dietary_profile: combinedProfiles
  //   }, {
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   })
  //     .then(response => {
  //     // Add the new meal plan to the existing meal plans in state
  //       setMealPlans(prevMealPlans => ({
  //         ...prevMealPlans,
  //         [day]: response.data[day]
  //       }))
  //       setOpen(false)
  //     })
  //     .catch(error => {
  //       console.error('There was an error!', error)
  //       setOpen(false)
  //     })
  // }

  const generateMealPlan = (event) => {
    event.preventDefault()
    setOpen(true)
    const token = localStorage.getItem('token')

    axios.get(process.env.REACT_APP_API_URL + '/generator/v2/mealplans?coach_only=0&calorie_goal=' + parseInt(props.calorieGoal) + '&dietary_profile=' + parseInt(props.dietaryProfile) + '&protein_goal=' + parseInt(props.proteinGoal), {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      const mealPlans = response.data
      const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

      mealPlans.forEach(mealPlan => {
        const dayOfWeek = Object.keys(mealPlan)[0] // Gets the first (and only) property name
        if (daysOfWeek.includes(dayOfWeek)) {
          setMealPlans(prevMealPlans => ({
            ...prevMealPlans,
            [dayOfWeek]: mealPlan[dayOfWeek]
          }))
        }
      })
    }).catch(error => {
      console.error('There was an error!', error)
    }).finally(() => {
      setOpen(false) // Close the dialog after operation is completed
    })
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mx: 'auto', mt: 2 }}>
      <Grid container direction="column" spacing={2}>
        {/*
        <Grid item><TextField label="MealPlan Name" value={mealPlanName} onChange={(e) => setMealPlanName(e.target.value)}  /></Grid>
        <Grid item><TextField label="Description" value={description} onChange={(e) => setDescription(e.target.value)}  /></Grid>
        */ }
        <Grid container spacing={2}><Box sx={{ padding: 5 }}>
          <Grid container spacing={2}>
            {Object.entries(DIETARY_PROFILES).map(([value, label]) => (
              <Grid item key={value}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!selectedProfiles[value]}
                      onChange={() => setSelectedProfiles(prev => ({
                        ...prev,
                        [value]: !prev[value]
                      }))}
                    />
                  }
                  label={label}
                />
              </Grid>
            ))}</Grid></Box>
        </Grid>

        <Grid item><Button onClick={generateMealPlan} variant="contained" color="primary">Generate MealPlan</Button></Grid>
        <Grid item><Button type="submit" variant="contained" color="primary">Add MealPlan</Button></Grid>
      </Grid>
      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
      >
        <DialogContent>
            Generating MealPlan for {props.name} to {props.goal} weight with a target caloric intake of {Math.round(props.calorieGoal)}kCal and protein goal of {props.proteinGoal}...
        </DialogContent>
      </Dialog>

      {Object.entries(mealPlans).length > 0 && (
        <Box sx={{ mx: 'auto', mt: 2 }}>
            Summary: Meal plan for {props.name} to {props.goal} weight with a target caloric intake of {Math.round(props.calorieGoal)}kCal and protein goal of {props.proteinGoal}. This assumes the client will be performing exercises which average a daily expenditure of {props.exerciseCalories}.
        </Box>
      )}

<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {Object.keys(mealPlans).map(day => (
              <TableCell key={day}>{day}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {['breakfast', 'lunch', 'dinner', 'bonus', 'snack1', 'snack2'].map(mealType => {
            return (
              <TableRow key={mealType}>
                <TableCell component="th" scope="row">{mealType}</TableCell>
                {Object.values(mealPlans).map((plan, index) => {
                  const meal = meals.find(meal => meal.id === plan[mealType]) || {}
                  return (
                    <TableCell key={index}>
                      {meal.name || 'N/A'}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
          {['total_calories', 'total_protein', 'total_carbs', 'total_fat'].map(nutrient => {
            return (
              <TableRow key={nutrient}>
                <TableCell component="th" scope="row">{nutrient}</TableCell>
                {Object.values(mealPlans).map((plan, index) => {
                  return (
                    <TableCell key={index}>
                      {plan && plan[nutrient] ? plan[nutrient] : 'N/A'}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
  )
}

AddMeal.propTypes = {
  dietaryProfile: PropTypes.number,
  exerciseCalories: PropTypes.number,
  updateDietaryProfile: PropTypes.func,
  name: PropTypes.string,
  clientId: PropTypes.number,
  calorieGoal: PropTypes.number,
  proteinGoal: PropTypes.number,
  goal: PropTypes.string
}

export default AddMeal
