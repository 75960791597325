import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import EditClient from '../../components/EditClient'

const CoachEditClient = () => {
  useEffect(() => {
  }, [])

  return (
    <Box sx={{ textAlign: 'left', padding: '20px', mx: 'auto', whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
      <Typography sx={{ padding: '0px' }}variant="h4">
         Editing Client
      </Typography>
      <EditClient />
    </Box>
  )
}

export default CoachEditClient
