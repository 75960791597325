import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import AddExercise from '../../components/AddExercise'
import { decodeToken } from 'react-jwt'

const CoachAddExercise = () => {
  const [decodedToken, setDecodedToken] = useState({ role: '' })
  useEffect(() => {
    const token = localStorage.getItem('token')
    setDecodedToken(decodeToken(token))
  }, [])

  return (
    <Box sx={{ textAlign: 'left', padding: '20px', mx: 'auto', whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
      <Typography sx={{ padding: '0px' }}variant="h4">
          Add An Exercise
      </Typography>
      {decodedToken.role === 'admin' && <AddExercise />}
      {decodedToken.role !== 'admin' && 'Sorry. Admin only. Coming soon to Coaches!'}
    </Box>
  )
}

export default CoachAddExercise
