import React, { useState } from 'react'
import { Box, Button, TextField, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { decodeToken } from 'react-jwt'
import { useLogin } from './components/LoginContext'
import PropTypes from 'prop-types'

const Login = ({ onLogin, type }) => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { handleLogin } = useLogin()

  const handleSubmit = async e => {
    e.preventDefault()
    // make a post request to your api route
    try {
      let url = process.env.REACT_APP_API_URL + '/clients/login'
      if (type === 'coach') {
        url = process.env.REACT_APP_API_URL + '/coaches/login'
      }
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
      })
      if (response.status === 401) {
        setErrorMessage('Invalid username or password. Please try again.')
      } else {
        const data = await response.json()
        if (data && data.token) {
          localStorage.setItem('token', data.token)
          const decodedToken = decodeToken(data.token)
          handleLogin(decodedToken)
          onLogin(decodedToken)
          if (decodedToken.role === 'admin') {
            navigate('/coaches')
          } else if (decodedToken.role === 'coach') {
            navigate('/coaches')
          } else {
            navigate('/clients')
          }
        } else {
          // handle error
          console.log(data.message)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Grid container justifyContent="center">
      <Box component="form" onSubmit={handleSubmit} p={2} m={2}>
      <Typography sx={{ padding: '0px' }}variant="h4">
         {type === 'client' ? 'Client Login' : 'Coach Login'}
      </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        {errorMessage &&
          <Typography variant="body1" color="error">
            {errorMessage}
          </Typography>
        }
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          mt={3}
        >
          Sign In
        </Button>
        { type === 'client' && <div style={{ paddingTop: '20px' }}><Button variant="contained" onClick={() => navigate('/coaches/login')}>
            Coach Login
          </Button></div>}
      </Box>
    </Grid>
  )
}

Login.propTypes = {
  onLogin: PropTypes.func,
  type: PropTypes.string
}

export default Login
