// At the top of your ListClients.js file
import { getDietaryProfiles } from '../DietaryProfiles'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '@mui/material'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Cancel from '@mui/icons-material/Cancel'
import PropTypes from 'prop-types'

const ListMeals = ({ coachId }) => {
  console.log('Coach ID: ' + coachId)
  const [meals, setMeals] = useState([])
  const [coaches, setCoaches] = useState({})
  const [loading, setLoading] = useState(false)
  // const [sortConfig, setSortConfig] = useState({ key: 'calories', direction: 'ascending' })
  const [sortConfig, setSortConfig] = useState({})

  const onSort = (key) => {
    let direction = 'ascending'
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  const [open, setOpen] = useState(false)
  const [selectedMealId, setSelectedMealId] = useState(null)
  const [comment, setComment] = useState('')

  const handleClickOpen = (mealId) => {
    setSelectedMealId(mealId)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleGo = () => {
    recalc(selectedMealId, comment)
    setLoading(true)
    setOpen(false)
  }

  const recalc = (mealId, comment) => {
    const token = localStorage.getItem('token')
    axios.post(process.env.REACT_APP_API_URL + '/meal/' + mealId + '/recalculate', {
      comment
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setLoading(false)
        setMeals(response.data)
        setComment('')
      })
      .catch(error => {
        setLoading(false)
        console.error('There was an error!', error)
      })
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    axios.get(process.env.REACT_APP_API_URL + '/meals?coach_id=' + coachId, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setMeals(response.data)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })

    axios.get(process.env.REACT_APP_API_URL + '/coaches', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        // Map data to object with coach IDs as keys for easy lookup
        const coachData = response.data.reduce((acc, curr) => {
          acc[curr.id] = curr.name
          return acc
        }, {})
        setCoaches(coachData)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }, [])

  const sortedMeals = [...meals]

  if (sortConfig !== null) {
    sortedMeals.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1
      }
      return 0
    })
  }

  return (
    <div>
      <Dialog
        open={loading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Updating...'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CircularProgress />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Comment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your comment below:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Comment"
            type="text"
            fullWidth
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleGo} color="primary">
            Go
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
            <TableCell sortDirection={sortConfig.direction}>
      <TableSortLabel active={sortConfig.key === 'verified'} direction={sortConfig.direction} onClick={() => onSort('verified')}>Verified?</TableSortLabel>
    </TableCell>
            <TableCell sortDirection={sortConfig.direction}>
      <TableSortLabel active={sortConfig.key === 'name'} direction={sortConfig.direction} onClick={() => onSort('name')}>Name</TableSortLabel>
    </TableCell>
    <TableCell sortDirection={sortConfig.direction}>
      <TableSortLabel active={sortConfig.key === 'description'} direction={sortConfig.direction} onClick={() => onSort('description')}>Description</TableSortLabel>
    </TableCell>
    <TableCell sortDirection={sortConfig.direction}>
      <TableSortLabel active={sortConfig.key === 'calories'} direction={sortConfig.direction} onClick={() => onSort('calories')}>Calories</TableSortLabel>
    </TableCell>
    <TableCell sortDirection={sortConfig.direction}>
      <TableSortLabel active={sortConfig.key === 'protein'} direction={sortConfig.direction} onClick={() => onSort('protein')}>Protein</TableSortLabel>
    </TableCell>
    <TableCell sortDirection={sortConfig.direction}>
      <TableSortLabel active={sortConfig.key === 'carbs'} direction={sortConfig.direction} onClick={() => onSort('carbs')}>Carbs</TableSortLabel>
    </TableCell>
    <TableCell sortDirection={sortConfig.direction}>
      <TableSortLabel active={sortConfig.key === 'fats'} direction={sortConfig.direction} onClick={() => onSort('fats')}>Fats</TableSortLabel>
    </TableCell>
              <TableCell>Dietary Profile</TableCell>
              <TableCell>Snack?</TableCell>
              <TableCell>Breakfast?</TableCell>
              <TableCell>Coach?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedMeals.map((meal) => (
              <TableRow
                key={meal.id}
              > <TableCell>
              {meal.verified ? <CheckCircle color="success" /> : <Cancel color="error" />}
            </TableCell>
                <TableCell onClick={() => handleClickOpen(meal.id)}>{meal.name}</TableCell>
                <TableCell>{meal.description}</TableCell>
                <TableCell>{meal.calories}</TableCell>
                <TableCell>{meal.protein}</TableCell>
                <TableCell>{meal.carbs}</TableCell>
                <TableCell>{meal.fats}</TableCell>
                <TableCell>{getDietaryProfiles(meal.dietary_profile)}</TableCell>
                <TableCell>{meal.is_snack ? 'Yes' : 'No'}</TableCell>
                <TableCell>{meal.is_breakfast ? 'Yes' : 'No'}</TableCell>
                <TableCell>{coaches[meal.coach_id]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
  )
}

ListMeals.propTypes = {
  coachId: PropTypes.number
}

export default ListMeals
