import React, { useEffect } from 'react'
import { Button, Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import ViewWorkout from '../components/ViewWorkout'
import { useNavigate } from 'react-router-dom'

const ClientPortal = ({ client, workout, userId, username }) => {
  const navigate = useNavigate()
  useEffect(() => {
  }, [])
  return (
    <Box sx={{ textAlign: 'left', padding: '20px', mx: 'auto', whiteSpace: 'break-spaces', overflowWrap: 'break-word' }}>
      <Typography variant="h4">
          Welcome to bdvoted, {username}!
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
        {workout && workout.id &&
        <div style={{ textAlign: 'left' }}>
          <p style={{ marginBottom: '5px' }}>It looks like your PT has assigned you a workout!</p>
          <Button type="submit" variant="contained" color="primary" onClick={() => navigate('/clients/workouts')}>Begin!</Button>
          <ViewWorkout workoutId={workout.id} />
        </div>}
      </div>
    </Box>
  )
}

ClientPortal.propTypes = {
  userId: PropTypes.number,
  username: PropTypes.string,
  client: PropTypes.object,
  workout: PropTypes.object
}

export default ClientPortal
