import React, { useEffect } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { useRoutes } from 'react-router-dom'
import { decodeToken } from 'react-jwt'
import CoachPortal from './coaches/Portal'
import CoachListClients from './coaches/clients/CoachListClients'
import CoachAddClient from './coaches/clients/CoachAddClient'
import CoachEditClient from './coaches/clients/CoachEditClient'
import CoachViewClient from './coaches/clients/CoachViewClient'
import CoachListMeals from './coaches/meals/CoachListMeals'
import CoachAddMeal from './coaches/meals/CoachAddMeal'
import CoachEditMeal from './coaches/meals/CoachEditMeal'
import CoachListExercises from './coaches/exercises/CoachListExercises'
import CoachAddExercise from './coaches/exercises/CoachAddExercise'
import CoachListWorkouts from './coaches/exercises/CoachListWorkouts'
import CoachEditWorkout from './coaches/exercises/CoachEditWorkout'
import CoachViewWorkout from './coaches/exercises/CoachViewWorkout'
import PropTypes from 'prop-types'

const Coaches = ({ userId, username, businessName, logoUrl }) => {
  const routes = useRoutes([
    { path: '/', element: <CoachPortal coachId={userId} username={username} businessName={businessName} logoUrl={logoUrl} /> },
    { path: '/clients/list', element: <CoachListClients /> },
    { path: '/clients/add', element: <CoachAddClient /> },
    { path: '/clients/:id/edit', element: <CoachEditClient /> },
    { path: '/clients/:id/view', element: <CoachViewClient /> },
    { path: '/meals/list', element: <CoachListMeals coachId={userId} /> },
    { path: '/meals/add', element: <CoachAddMeal coachId={userId} /> },
    { path: '/meals/:id/edit', element: <CoachEditMeal coachId={userId} /> },
    { path: '/exercises/list', element: <CoachListExercises coachId={userId} /> },
    { path: '/exercises/add', element: <CoachAddExercise coachId={userId} /> },
    { path: '/workouts/list', element: <CoachListWorkouts coachId={userId} /> },
    { path: '/workouts/:id/edit', element: <CoachEditWorkout coachId={userId} /> },
    { path: '/workouts/:id/view', element: <CoachViewWorkout coachId={userId} /> }
  ])

  const token = localStorage.getItem('token')
  const decodedToken = decodeToken(token)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (decodedToken && decodedToken.role !== 'admin' && decodedToken.role !== 'coach') {
      console.log('Redirecting sir')
      // navigate('/clients');
      console.log('isMobile: ' + isMobile)
    }
  })

  return (
    <div>
      <main>
      {routes}
      </main>
    </div>
  )
}

Coaches.propTypes = {
  userId: PropTypes.number,
  username: PropTypes.string,
  businessName: PropTypes.string,
  logoUrl: PropTypes.string
}

export default Coaches
