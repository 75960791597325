import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate()
  const [token, setToken] = useState(null)

  useEffect(() => {
    const tokenFromStorage = getToken() // Implement this function to retrieve the token from where you stored it.
    setToken(tokenFromStorage)

    if (!tokenFromStorage) {
      navigate('/')
    }
  }, [navigate])

  // If there's a token, render the children; otherwise, render nothing.
  return token ? children : null
}

const getToken = () => {
  // This is just an example.
  // Replace this with your actual code to get the token.
  const storedToken = localStorage.getItem('token')
  return storedToken
}

ProtectedRoute.propTypes = {
  children: PropTypes.element
}

export default ProtectedRoute
