import { Grid, Typography, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios'

const Checkins = () => {
  const [progress, setProgress] = useState([])

  useEffect(() => {
    const token = localStorage.getItem('token')

    axios.get(process.env.REACT_APP_API_URL + '/clients/checkins', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        const data = response.data
        setProgress(data)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }, [])
  return (
    <Grid>
      {progress.map((p) => (
        <Grid key={p.id} container direction="column" spacing={2} sx={{ marginBottom: '20px' }}>
          <Paper sx={{ padding: '10px', marginBottom: '10px', minHeight: '80px' }}>
            <Grid item sx={{ paddingTop: 0, fontWeight: 'bold' }}>{p.date}</Grid>
            <Grid item sx={{ paddingTop: '10px' }}>{p.message}</Grid>
            <Grid container spacing={2}>
              {p.supplemental && Object.entries(p.supplemental).map(([key, value], index) => (
                key !== 'Progress Back Picture' && key !== 'Progress Front Picture' && key !== 'Progress Side Picture' && key !== 'Hi {{hidden:client_name}}! What\'s your weight today? Please specify the unit!' && (
                  <Grid key={`${p.id}-${index}`} item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{key}</Typography>
                    <Typography variant="body2">{typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}</Typography>
                  </Grid>
                )
              ))}
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

export default Checkins
