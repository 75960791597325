import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const ListWorkouts = ({ coachId }) => {
  console.log('Coach ID: ' + coachId)
  const [workouts, setWorkouts] = useState([])
  const [loading, setLoading] = useState(true)
  const [sortConfig, setSortConfig] = useState({})

  const onSort = (key) => {
    let direction = 'ascending'
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  const addWorkout = () => {
    const token = localStorage.getItem('token')
    axios.post(process.env.REACT_APP_API_URL + '/workouts', {}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        getWorkouts()
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
      .finally(
        setLoading(false)
      )
  }

  const getWorkouts = () => {
    const token = localStorage.getItem('token')
    axios.get(process.env.REACT_APP_API_URL + '/workouts?coach_id=' + coachId, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setWorkouts(response.data)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
      .finally(
        setLoading(false)
      )
  }

  useEffect(() => {
    getWorkouts()
  }, [])

  const sortedWorkouts = [...workouts]

  if (sortConfig !== null) {
    sortedWorkouts.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1
      }
      return 0
    })
  }

  return (
    <div>
      <Dialog
        open={loading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Updating...'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CircularProgress />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sortDirection={sortConfig.direction}>
                <TableSortLabel active={sortConfig.key === 'name'} direction={sortConfig.direction} onClick={() => onSort('name')}>Name</TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sortConfig.direction}>
                <TableSortLabel active={sortConfig.key === 'description'} direction={sortConfig.direction} onClick={() => onSort('description')}>Description</TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedWorkouts.map((workout) => (
              <TableRow
                key={workout.id}
              >
                <TableCell><Link to={`/coaches/workouts/${workout.id}/view`}>{workout.name}</Link></TableCell>
                <TableCell>{workout.description}</TableCell>
              </TableRow>
            ))}
            {coachId > 0 && <TableRow><TableCell><AddIcon onClick={() => addWorkout()} />Add Workout</TableCell></TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
  )
}

ListWorkouts.propTypes = {
  coachId: PropTypes.number
}

export default ListWorkouts
