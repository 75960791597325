import * as React from 'react'
import { useRoutes } from 'react-router-dom'
import AddCoach from './coaches/AddCoach'
import ListCoaches from './coaches/ListCoaches'

const Coaches = () => {
  const routes = useRoutes([
    { path: '/', element: <ListCoaches /> },
    { path: 'add', element: <AddCoach /> }
  ])

  return (
    <div style={{ display: 'flex' }}>
      <main>
        {routes}
      </main>
    </div>
  )
}

export default Coaches
