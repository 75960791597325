import { Box, Button, Dialog, Typography, TextField, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import AddIcon from '@mui/icons-material/Add'
import Cancel from '@mui/icons-material/Cancel'
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import WorkoutDay from './workout/WorkoutDay'
import PropTypes from 'prop-types'
import { decodeToken } from 'react-jwt'

const EditWorkout = ({ coachId }) => {
  const { id } = useParams()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [workout, setWorkout] = useState(null)
  const [allExercises, setAllExercises] = useState([])
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [decodedToken, setDecodedToken] = useState({ role: '' })
  const navigate = useNavigate()

  const openDialog = () => {
    setOpenDeleteDialog(true)
  }

  const closeDialog = () => {
    setOpenDeleteDialog(false)
  }

  const saveWorkout = (event) => {
    event.preventDefault()
    const exercisesInfo = {}
    let i = 0
    let nr = 0
    // Iterate over each day's exercises
    workout.exercises.forEach(day => {
      nr++
      if (day.sort_groups) {
        day.sort_groups.forEach(group => {
          if (group.exercises) {
            group.exercises.forEach(exercise => {
              exercisesInfo[i++] = {
                sort: group.sort,
                superset: group.superset,
                exercise_id: exercise.id,
                day: nr,
                reps: exercise.reps,
                sets: exercise.sets
              }
            })
          }
        })
      }
    })

    const url = `${process.env.REACT_APP_API_URL}/workout/${id}`
    const token = localStorage.getItem('token')

    axios.post(url, { name, description, data: exercisesInfo }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        // console.log('Workout updated successfully:', response.data)
        navigate(`/coaches/workouts/${id}/view`)
      })
      .catch(error => {
        console.error('There was an error updating the workout!', error)
      })
  }

  const deleteWorkout = (event) => {
    event.preventDefault()
    const token = localStorage.getItem('token')
    const url = `${process.env.REACT_APP_API_URL}/workout/${id}`
    axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        // console.log('Workout updated successfully:', response.data)
        navigate('/coaches/workouts/list')
      })
      .catch(error => {
        console.error('There was an error deleting the workout!', error)
      })
      .finally(e => {
        closeDialog()
      })
  }
  const updateWorkout = (day, exercises) => {
    const updatedWorkouts = JSON.parse(JSON.stringify(workout))
    updatedWorkouts.exercises[day - 1].sort_groups = exercises
    setWorkout(updatedWorkouts)
  }

  const handleNameChange = (name) => {
    const newName = name

    if (workout) {
      setWorkout(prevWorkout => ({ ...prevWorkout, name: newName }))
    }
  }

  const handleDescriptionChange = (desc) => {
    const newDescription = desc

    if (workout) {
      setWorkout(prevWorkout => ({ ...prevWorkout, description: newDescription }))
    }
  }

  const addDay = () => {
    const newDay = {
      day_number: workout.exercises.length + 1,
      sort_groups: [
        {
          exercises: [],
          muscle_groups: 'PlaceHolder',
          superset: false,
          sort: 1
        }]
    }
    const updatedWorkout = JSON.parse(JSON.stringify(workout))
    updatedWorkout.exercises.push(newDay)
    setWorkout(updatedWorkout)
  }

  const removeDay = (dayIndex) => {
    const updatedWorkout = JSON.parse(JSON.stringify(workout))
    updatedWorkout.exercises.splice(dayIndex, 1)
    setWorkout(updatedWorkout)
  }

  const moveDayDown = (dayIndex) => {
    const updatedWorkout = JSON.parse(JSON.stringify(workout))
    // console.log(workout)

    if (dayIndex < updatedWorkout.exercises.length - 1) {
      const temp = updatedWorkout.exercises[dayIndex]
      updatedWorkout.exercises[dayIndex] = updatedWorkout.exercises[dayIndex + 1]
      updatedWorkout.exercises[dayIndex + 1] = temp
      setWorkout(updatedWorkout)
    } else {
      console.log("Cannot move the day down as it's the last one in the list.")
    }
  }

  const moveDayUp = (dayIndex) => {
    const updatedWorkout = JSON.parse(JSON.stringify(workout))
    // console.log(workout)

    if (dayIndex > 0) {
      const temp = updatedWorkout.exercises[dayIndex]
      updatedWorkout.exercises[dayIndex] = updatedWorkout.exercises[dayIndex - 1]
      updatedWorkout.exercises[dayIndex - 1] = temp
      setWorkout(updatedWorkout)
    } else {
      console.log("Cannot move the day up as it's the first one in the list.")
    }
  }

  useEffect(() => {
    if (workout) {
      setName(workout.name)
      setDescription(workout.description)
      if (workout.exercises.length === 0) {
        addDay()
      }
    }
  }, [workout])

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/exercises`
    const token = localStorage.getItem('token')

    axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setAllExercises(response.data)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('token')

    const url = `${process.env.REACT_APP_API_URL}/workout/${id}`
    setDecodedToken(decodeToken(token))
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        const workoutReponse = response.data
        setWorkout(workoutReponse)
      })
      .catch(error => {
        console.error('There was an error!', error)
      })
  }, [])

  return (
    ((workout && workout.coach_id !== coachId) && decodedToken.role !== 'admin')
      ? <p>Sorry, this workout does not belong to you and cannot be edited. {decodedToken.role}</p>
      : (
      <div>
        <Dialog
          open={openDeleteDialog}
          onClose={closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{'Confirm Deletion'}</DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this workout?
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={closeDialog} color="primary">
                  Cancel
              </Button>
              <Button onClick={deleteWorkout} color="primary" autoFocus>
                  Yes, Delete
              </Button>
          </DialogActions>
        </Dialog>

        <Button onClick={saveWorkout} variant="contained" color="primary">Save</Button>&nbsp;
        <Button onClick={(e) => openDialog()} variant="contained" color="error">Delete</Button>
        <Box sx={{ mx: 'auto', mt: 2 }}>
          <TextField sx={{ width: '100%', margin: 'auto', mt: 2 }} label="Name" value={name} onChange={(e) => handleNameChange(e.target.value)} /><br/>
          <TextField sx={{ width: '100%', margin: 'auto', mt: 2 }} multiline rows={4} label="Description" value={description} onChange={(e) => handleDescriptionChange(e.target.value)} />
          {workout && workout.exercises && workout.exercises.length > 0 && workout.exercises.map((exercises, index) => (
            <div key={exercises.day_number}>
              <Typography sx={{ padding: '0px', marginBottom: '0px' }}variant='h5'>
                <Cancel onClick={() => removeDay(index)} color="error" /> Day {exercises.day_number} <ArrowUpwardIcon onClick={() => moveDayUp(index)} /> <ArrowDownwardIcon onClick={() => moveDayDown(index)} />
              </Typography>
              {exercises && exercises.length} {
                <WorkoutDay updateWorkout={updateWorkout} edit={true} key={exercises.day_number} day={index + 1} allExercises={allExercises} exercises={exercises.sort_groups} />
              }
            </div>
          ))}
          <AddIcon onClick={() => addDay()} /> Add Another Day
        </Box>
      </div>
        )
  )
}

EditWorkout.propTypes = {
  coachId: PropTypes.number
}

export default EditWorkout
